import Api from '../Core/Helpers/Api';

const authApi = Api.create('auth');

const ExportDefault = {
  login: function(options) {
    return authApi.post(`login`,options);
  },
  register: function(options) {
    return authApi.post(`register`,options);
  },
  status: function(options) {
    return authApi.get(`status`,options);
  }
};

export default ExportDefault;