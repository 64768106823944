import String from "./String";
import Language from "./Language";
import Env from "./Env";
import ShareApi from "../../Api/Share";

class Literal {
  literals = [];

  set(literals) {
    this.literals = literals;
    return this;
  }

  get() {
    return this.literals || [];
  }

  trans(slug) {
    const { translation } =
      this.get().find((literal) => literal.slug === slug) || {};

    return translation ? translation : slug ? slug.replaceAll("-", " ") : slug;
  }

  ucfirst(slug) {
    return String.ucfirst(this.trans(slug));
  }

  retrieve(options) {
    return new Promise((resolve, reject) => {
      const { account, repository } = options || {};

      const lang = Language.getLang();
      const app = Env.get("name");
      const defaultAccount = Env.get("default_account", "automatapp");
      const defaultRepository = Env.get("default_repository", app);
      const platformVersion = Env.get("platform_version", "1");

      if (platformVersion === "2") {
        ShareApi.content
          .literals(
            account || defaultAccount,
            repository || defaultRepository,
            lang
          )
          .then((response) => {
            this.set(response.getData());

            resolve();
          })
          .catch((e) => reject(e));
      } else {
        ShareApi.literal
          .app(defaultAccount, lang, app)
          .then((response) => {
            this.set(response.getData());
            resolve();
          })
          .catch((e) => reject(e));
      }
    });
  }

  changeLanguage(language, options) {
    Language.setLang(language);
    return this.retrieve(options);
  }
}

export { Literal };
export default new Literal();
