import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";

const ExportDefault = (props) => {
  return (
    <div className="divider-top">
      <div className="container">
        <Translate
          entity="literal"
          eslug="about-us"
          scope="default"
          slug="default"
          auto
        />
      </div>
    </div>
  );
};

export default ExportDefault;
