import React from "react";

const getCategoryImage = (props, type) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  const categories = component.getData("default.categories", []);
  const category = categories.find((category) => category.slug === type);
  if (category) {
    return urlHelper.createAccountImageUrl(
      account,
      repository,
      "item",
      category.slug,
      "default",
      "1"
    );
  }
};

const getCategoryTotals = (props, type) => {
  const { component } = props;
  const categories = component.getData("default.categories", []);
  const category = categories.find((category) => category.slug === type) || {};
  const { total_items = 0 } = category;
  return total_items;
};

const getCategoryName = (props, type) => {
  const { component } = props;
  const categories = component.getData("default.categories", []);
  const category = categories.find((category) => category.slug === type) || {};
  const { name = "" } = category;
  return name;
};

const ExportDefault = (props) => {
  return (
    <div className="page-content">
      <div className="mt-3"></div>
      <div className="categories-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="banner banner-cat banner-badge">
                <a
                  href="/category/sofas"
                  style={{
                    height: "574px",
                    backgroundImage: `url(${getCategoryImage(props, "sofas")})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {" "}
                </a>

                <a className="banner-link" href="/category/sofas">
                  <h3 className="banner-title">
                    {getCategoryName(props, "sofas")}
                  </h3>
                  {/*  End .banner-title */}
                  <h4 className="banner-subtitle">
                    {getCategoryTotals(props, "sofas")} Προϊόντα
                  </h4>
                  {/*  End .banner-subtitle */}
                  <span className="banner-link-text">Περισσότερα</span>
                </a>
                {/*  End .banner-link */}
              </div>
              {/*  End .banner */}

              <div className="banner banner-cat banner-badge">
                <a
                  href="/category/tables"
                  style={{
                    height: "277px",
                    backgroundImage: `url(${getCategoryImage(
                      props,
                      "tables"
                    )})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {" "}
                </a>

                <a className="banner-link" href="/category/tables">
                  <h3 className="banner-title">
                    {getCategoryName(props, "tables")}
                  </h3>
                  {/*  End .banner-title */}
                  <h4 className="banner-subtitle">
                    {getCategoryTotals(props, "tables")} Προϊόντα
                  </h4>
                  {/*  End .banner-subtitle */}
                  <span className="banner-link-text">Περισσότερα</span>
                </a>
                {/*  End .banner-link */}
              </div>
              {/*  End .banner */}
            </div>
            {/*  End .col-md-6 */}

            <div className="col-md-6">
              <div className="row">
                <div className="col-sm-6">
                  <div className="banner banner-cat banner-badge">
                    <a
                      href="/category/chairs"
                      style={{
                        height: "277px",
                        backgroundImage: `url(${getCategoryImage(
                          props,
                          "chairs"
                        )})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {" "}
                    </a>

                    <a className="banner-link" href="/category/chairs">
                      <h3 className="banner-title">
                        {getCategoryName(props, "chairs")}
                      </h3>
                      {/*  End .banner-title */}
                      <h4 className="banner-subtitle">
                        {getCategoryTotals(props, "chairs")} Προϊόντα
                      </h4>
                      {/*  End .banner-subtitle */}
                      <span className="banner-link-text">Περισσότερα</span>
                    </a>
                    {/*  End .banner-link */}
                  </div>
                  {/*  End .banner */}
                </div>
                {/*  End .col-sm-6 */}

                <div className="col-sm-6">
                  <div className="banner banner-cat banner-badge">
                    <a
                      href="/category/beds"
                      style={{
                        height: "277px",
                        backgroundImage: `url(${getCategoryImage(
                          props,
                          "beds"
                        )})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {" "}
                    </a>

                    <a className="banner-link" href="/category/beds">
                      <h3 className="banner-title">
                        {getCategoryName(props, "beds")}
                      </h3>
                      {/*  End .banner-title */}
                      <h4 className="banner-subtitle">
                        {getCategoryTotals(props, "beds")} Προϊόντα
                      </h4>
                      {/*  End .banner-subtitle */}
                      <span className="banner-link-text">Περισσότερα</span>
                    </a>
                    {/*  End .banner-link */}
                  </div>
                  {/*  End .banner */}
                </div>
                {/*  End .col-sm-6 */}
              </div>
              {/*  End .row */}

              <div className="banner banner-cat banner-badge">
                <a
                  href="/category/living-room-tables"
                  style={{
                    height: "574px",
                    backgroundImage: `url(${getCategoryImage(
                      props,
                      "living-room-tables"
                    )})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {" "}
                </a>

                <a className="banner-link" href="/category/living-room-tables">
                  <h3 className="banner-title">
                    {getCategoryName(props, "living-room-tables")}
                  </h3>
                  {/*  End .banner-title */}
                  <h4 className="banner-subtitle">
                    {getCategoryTotals(props, "living-room-tables")} Προϊόντα
                  </h4>
                  {/*  End .banner-subtitle */}
                  <span className="banner-link-text">Περισσότερα</span>
                </a>
                {/*  End .banner-link */}
              </div>
              {/*  End .banner */}
            </div>
            {/*  End .col-md-6 */}
            <div className="col-md-6">
              <div className="banner banner-cat banner-badge">
                <a
                  href="/category/lighting-outfits"
                  style={{
                    height: "574px",
                    backgroundImage: `url(${getCategoryImage(
                      props,
                      "lighting-outfits"
                    )})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {" "}
                </a>

                <a className="banner-link" href="/category/lighting-outfits">
                  <h3 className="banner-title">
                    {getCategoryName(props, "lighting-outfits")}
                  </h3>
                  {/*  End .banner-title */}
                  <h4 className="banner-subtitle">
                    {getCategoryTotals(props, "lighting-outfits")} Προϊόντα
                  </h4>
                  {/*  End .banner-subtitle */}
                  <span className="banner-link-text">Περισσότερα</span>
                </a>
                {/*  End .banner-link */}
              </div>

              {/*  End .banner */}
            </div>
            {/* order-md-last*/}

            <div className="col-md-6">
              <div className="banner banner-cat banner-badge">
                <a
                  href="/category/buffets"
                  style={{
                    height: "277px",
                    backgroundImage: `url(${getCategoryImage(
                      props,
                      "buffets"
                    )})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  {" "}
                </a>

                <a className="banner-link" href="/category/buffets">
                  <h3 className="banner-title">
                    {getCategoryName(props, "buffets")}
                  </h3>
                  {/*  End .banner-title */}
                  <h4 className="banner-subtitle">
                    {getCategoryTotals(props, "buffets")} Προϊόντα
                  </h4>
                  {/*  End .banner-subtitle */}
                  <span className="banner-link-text">Περισσότερα</span>
                </a>
                {/*  End .banner-link */}
              </div>
              {/*  End .banner */}
              <div className="row">
                <div className="col-sm-6">
                  <div className="banner banner-cat banner-badge">
                    <a
                      href="/category/armchairs"
                      style={{
                        height: "277px",
                        backgroundImage: `url(${getCategoryImage(
                          props,
                          "armchairs"
                        )})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {" "}
                    </a>

                    <a className="banner-link" href="/category/armchairs">
                      <h3 className="banner-title">
                        {getCategoryName(props, "armchairs")}
                      </h3>
                      {/*  End .banner-title */}
                      <h4 className="banner-subtitle">
                        {getCategoryTotals(props, "armchairs")} Προϊόντα
                      </h4>
                      {/*  End .banner-subtitle */}
                      <span className="banner-link-text">Περισσότερα</span>
                    </a>
                    {/*  End .banner-link */}
                  </div>
                  {/*  End .banner */}
                </div>
                {/*  End .col-sm-6 */}

                <div className="col-sm-6">
                  <div className="banner banner-cat banner-badge">
                    <a
                      href="/category/others"
                      style={{
                        height: "277px",
                        backgroundImage: `url(${getCategoryImage(
                          props,
                          "others"
                        )})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {" "}
                    </a>

                    <a className="banner-link" href="/category/others">
                      <h3 className="banner-title">
                        {getCategoryName(props, "others")}
                      </h3>
                      {/*  End .banner-title */}
                      <h4 className="banner-subtitle">
                        {getCategoryTotals(props, "others")} Προϊόντα
                      </h4>
                      {/*  End .banner-subtitle */}
                      <span className="banner-link-text">Περισσότερα</span>
                    </a>
                    {/*  End .banner-link */}
                  </div>
                  {/*  End .banner */}
                </div>
                {/*  End .col-sm-6 */}
              </div>
              {/*  End .row */}
            </div>
          </div>
          {/*  End .row */}
        </div>
        {/*  End .container */}
      </div>
      {/*  End .categories-page */}
    </div>
  );
};

export default ExportDefault;
