import App from '../../modules/Core/Components/App';
import template from '../templates/app';

export default class MainApp extends App {
    template = template;

    getOnResizeResolusion(width) {
      if (width < 500) {
        return 'mobile';
      } 

      if (width >= 500) {
        return 'default';
      }
    }
}