class Components {
    components = [];

    set(components) {
        this.components = components;
        return this;
    }

    get() {
        return this.components;
    }

    add(component) {
        this.components.push(component);
        return this;
    }

    removeById(id) {
        let index = -1;
        const components = this.get();

        components.every((component, i) => {
            if (component.getId() === id) {
                index = i;
                return false;
            }

            return true;
        });

        if (index >= 0) {
            components.splice(index,1);
        }

        return this;
    }

    findById(id) {
        const components = new Components();

        this.get().every(component => {
            if (component.getId() === id) {
                components.add(component);
                return false;
            }

            return true;
        });

        return components;
    }

    findByGroup(group) {
        const components = new Components();

        this.get().forEach(component => {
            if (component.hasGroup(group)) {
                components.add(component);
            }
        });

        return components;
    }

    first() {
        const components = this.get();
        return components.length ? components[0]: null;
    }

    forEach(cb) {
        this.get().forEach(component => {
            cb.call(this,component);
        });

        return this;
    }

    exec(method, params) {
        this.forEach(component => {
            if (component[method]) {
                component[method].call(component,params);
            }
        });

        return this;
    }

    refresh(params) {
        return this.exec('refresh',params);
    }
}

export { Components }
export default new Components();
