import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/wishlist";
import Services from "../../Services/Services";

export default class Wishlist extends Page {
  title = "home";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    const title = this.getHelpers("env").get("title");
    this.setWindowTitle(`${title} - ΑΓΑΠΗΜΕΝΑ`);

    Services.get("content").then(([content]) => {
      content.getAll("location").then(({ data }) => {
        this.setData({
          "default.locations": data,
        });
      });
    });

    this.getWishlist();

    this.addScripts({
      "jquery.min": "assets/js/jquery.min",
      "bootstrap.bundle.min": "assets/js/bootstrap.bundle.min",
      "jquery.hoverIntent.min": "assets/js/jquery.hoverIntent.min",
      "jquery.waypoints.min": "assets/js/jquery.waypoints.min",
      "superfish.min": "assets/js/superfish.min",
      "owl.carousel.min": "assets/js/owl.carousel.min",
      "jquery.plugin.min": "assets/js/jquery.plugin.min",
      "jquery.magnific-popup.min": "assets/js/jquery.magnific-popup.min",
      "jquery.countdown.min": "assets/js/jquery.countdown.min",
      main: "assets/js/main",
      "demo-2": "assets/js/demos/demo-2",
    });

    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  getWishlist() {
    const wishlist = this.getHelpers("state").get("wishlist");
    if (wishlist) {
      Services.get("content").then(([content]) => {
        var promises = wishlist.split(",").map((slug) => {
          return content.getOne("property", slug);
        });

        Promise.all(promises).then((items) => {
          items = items.map(({ data }) => data);

          this.setData({
            "default.wishlist": items,
          });
        });
      });
    }
  }

  removeFromWishList(slug) {
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");
    var wishlist = this.getData("default.wishlist");

    var array = stateHelper.get("wishlist").split(",");
    var index = wishlist.findIndex((el) => el.slug === slug);

    array.splice(index, 1);
    wishlist.splice(index, 1);
    stateHelper.set("wishlist", array.join(","), {
      domain: Env.get("domain"),
      path: "/",
    });

    this.setData({
      "default.wishlist": wishlist,
    });
  }

  checkSearch(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search").value.length > 1) {
      window.location.href =
        devHelper + "/results/" + document.getElementById("search").value;
    }
  }
  checkSearchMobile(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search_mobile").value.length > 1) {
      window.location.href =
        devHelper +
        "/results/" +
        document.getElementById("search_mobile").value;
    }
  }
}
