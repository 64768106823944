import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/home";
import Services from "../../Services/Services";

export default class Home extends Page {
  title = "home";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getVisited();

    Services.get("content").then(([content]) => {
      content.getAll("location").then(({ data }) => {
        this.setData({
          "default.locations": data,
          "default.page": 0,
          "default.wishlist": this.getWishlist(),
        });
      });
    });

    this.addScripts({
      "jquery.min": "assets/js/jquery.min",
      "bootstrap.bundle.min": "assets/js/bootstrap.bundle.min",
      "jquery.hoverIntent.min": "assets/js/jquery.hoverIntent.min",
      "jquery.waypoints.min": "assets/js/jquery.waypoints.min",
      "superfish.min": "assets/js/superfish.min",
      "jquery.plugin.min": "assets/js/jquery.plugin.min",
      "jquery.magnific-popup.min": "assets/js/jquery.magnific-popup.min",
      "jquery.countdown.min": "assets/js/jquery.countdown.min",
      "demo-2": "assets/js/demos/demo-2",
    });

    window.scrollTo({
      top: 0,
      left: 0,
    });

    this.setSeoData();
  }

  async setSeoData() {
    const account = this.getHelpers("env").getDefaultAccount();
    const repository = this.getHelpers("env").getDefaultRepository();

    const translations = await this.getHelpers("translate").scope({
      account,
      repository,
      entity: "item",
      entity_slug: "seo",
      scope: "default",
    });

    const description = translations.find(
      (translation) => translation.slug === "default-description"
    ) || { translation: "" };

    const keywords = translations.find(
      (translation) => translation.slug === "default-keywords"
    ) || { translation: "" };

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description.translation);

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", keywords.translation);
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    if (this.getData("default.visited")) {
      this.addScripts({
        "owl.carousel.min": "/assets/js/owl.carousel.min",
        main: "/assets/js/main",
      });
    }
  }

  getWishlist() {
    const wishlist = this.getHelpers("state").get("wishlist");
    if (wishlist) {
      var array = wishlist.split(",");
      return array;
    } else {
      return [];
    }
  }

  addToWishList(slug) {
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");
    // stateHelper.remove("wishlist", {
    //   domain: Env.get("domain"),
    //   path: "/",
    // });
    var array = [];
    var wishlist = stateHelper.get("wishlist", null);
    if (wishlist) {
      array = wishlist.split(",");
      if (!array.includes(slug)) {
        array.push(slug);
      }
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    } else {
      array.push(slug);
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    }

    this.setData({
      "default.wishlist": array,
    });
  }

  removeFromWishList(slug) {
    if (this.getPage().getParam("slug") === slug) {
      this.setData({
        "default.added": false,
      });
    }
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");

    var array = stateHelper.get("wishlist").split(",");
    var index = array.findIndex((el) => el === slug);
    array.splice(index, 1);
    stateHelper.set("wishlist", array.join(","), {
      domain: Env.get("domain"),
      path: "/",
    });

    this.setData({
      "default.wishlist": array,
    });
  }

  getVisited() {
    const visited = this.getHelpers("state").get("visited");
    const wishlist = this.getHelpers("state").get("wishlist");
    var array = [];
    if (wishlist) {
      array = wishlist.split(",");
    }

    if (visited) {
      Services.get("content").then(([content]) => {
        var promises = visited.split(",").map((slug) => {
          return content.getOne("property", slug);
        });

        Promise.all(promises).then((items) => {
          items = items.map(({ data }) => {
            var temp = data;

            if (temp) {
              temp["added"] = false;
              array.forEach((slug) => {
                if (temp.slug === slug) {
                  temp["added"] = true;
                }
              });
            }

            return temp;
          });

          this.setData({
            "default.visited": items.filter((item) => item),
          });
        });
      });
    } else {
      this.setData({
        "default.visited": [],
      });
    }
  }

  checkSearch(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search").value.length > 1) {
      window.location.href =
        devHelper + "/results/" + document.getElementById("search").value;
    }
  }
  checkSearchMobile(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search_mobile").value.length > 1) {
      window.location.href =
        devHelper +
        "/results/" +
        document.getElementById("search_mobile").value;
    }
  }
}
