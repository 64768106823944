import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <div className="intro-slider-container">
      <div
        className="owl-carousel owl-simple owl-light owl-nav-inside"
        data-toggle="owl"
        data-owl-options='{"nav": false,"autoplay": true,"loop":true, "autoplayTimeout":5000}'
      >
        <div
          className="intro-slide"
          style={{
            backgroundImage: `url(${urlHelper.createAccountImageUrl(
              account,
              repository,
              "item",
              "gallery",
              "default",
              "slider1"
            )})`,
          }}
        >
          <div className="container intro-content">
            <h1 className="intro-title text-center">
              <Translate
                entity="literal"
                eslug="slider-quote"
                scope="default"
                slug="default"
                auto
              />
            </h1>
          </div>
        </div>
        <div
          className="intro-slide"
          style={{
            backgroundImage: `url(${urlHelper.createAccountImageUrl(
              account,
              repository,
              "item",
              "gallery",
              "default",
              "slider2"
            )})`,
          }}
        >
          <div className="container intro-content">
            <h1 className="intro-title text-center">
              <Translate
                entity="literal"
                eslug="slider-quote"
                scope="default"
                slug="default"
                auto
              />
            </h1>
          </div>
        </div>
        {/* End .intro-slide */}

        <div
          className="intro-slide"
          style={{
            backgroundImage: `url(${urlHelper.createAccountImageUrl(
              account,
              repository,
              "item",
              "gallery",
              "default",
              "slider3"
            )})`,
          }}
        >
          <div className="container intro-content">
            <h1 className="intro-title text-center">
              <Translate
                entity="literal"
                eslug="slider-quote"
                scope="default"
                slug="default"
                auto
              />
            </h1>
          </div>
        </div>
      </div>
      <span className="slider-loader text-white"></span>
    </div>
  );
};

export default ExportDefault;
