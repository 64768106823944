import React from "react";

const ExportDefault = (props) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="mt-3"></div>
        <div className="container">
          <h2 className="title text-center mb-3">Συχνές Ερωτήσεις</h2>
          {/* End .title */}
          <div className="accordion accordion-rounded" id="accordion-1">
            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-1">
                <h2 className="card-title">
                  <a
                    role="button"
                    data-toggle="collapse"
                    href="#collapse-1"
                    aria-expanded="true"
                    aria-controls="collapse-1"
                  >
                    Γιατί είναι πιο οικονομικά;
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div
                id="collapse-1"
                className="collapse show"
                aria-labelledby="heading-1"
                data-parent="#accordion-1"
              >
                <div className="card-body">
                  Θα βρείτε προϊόντα τα οποία ήταν εκθέματα στα καταστήματά μας
                  και λόγω ανανέωσης των εκθέσεών μας με νέα προϊόντα σας τα
                  προσφέρουμε σε πραγματικά καλές τιμές!
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}

            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-2">
                <h2 className="card-title">
                  <a
                    className="collapsed"
                    role="button"
                    data-toggle="collapse"
                    href="#collapse-2"
                    aria-expanded="false"
                    aria-controls="collapse-2"
                  >
                    Η παράδοση είναι δωρεάν;
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div
                id="collapse-2"
                className="collapse"
                aria-labelledby="heading-2"
                data-parent="#accordion-1"
              >
                <div className="card-body">
                  Η παράδοση είναι δωρεάν για την Αττική και μπορούμε να
                  καλύψουμε και πιο μακρινές διαδρομές εξίσου δωρεάν κατόπιν
                  συνεννόησης.
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}

            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-3">
                <h2 className="card-title">
                  <a
                    className="collapsed"
                    role="button"
                    data-toggle="collapse"
                    href="#collapse-3"
                    aria-expanded="false"
                    aria-controls="collapse-3"
                  >
                    Πώς μπορώ να πληρώσω;
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div
                id="collapse-3"
                className="collapse"
                aria-labelledby="heading-3"
                data-parent="#accordion-1"
              >
                <div className="card-body">
                  Έκτος από μετρητά δεχόμαστε όλες τις κάρτες της αγοράς και
                  μπορούμε να σας προσφέρουμε έως και 12 άτοκες δόσεις.
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}

            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-4">
                <h2 className="card-title">
                  <a
                    className="collapsed"
                    role="button"
                    data-toggle="collapse"
                    href="#collapse-4"
                    aria-expanded="false"
                    aria-controls="collapse-4"
                  >
                    Πόσο σύντομα μπορείτε να παραδόσετε;
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div
                id="collapse-4"
                className="collapse"
                aria-labelledby="heading-4"
                data-parent="#accordion-1"
              >
                <div className="card-body">
                  Αν το προϊόν είναι σε στοκ μπορούμε και εντός μιας εβδομάδας,
                  και για νέα προϊόντα σε συμφωνία με την παραγωγή μπορούμε να
                  επισπεύσουμε την παραγγελία.
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}

            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-5">
                <h2 className="card-title">
                  <a
                    className="collapsed"
                    role="button"
                    data-toggle="collapse"
                    href="#collapse-5"
                    aria-expanded="false"
                    aria-controls="collapse-5"
                  >
                    Μπορείτε να με βοηθήσετε με τις διαστάσεις του χώρου μου;
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div
                id="collapse-5"
                className="collapse"
                aria-labelledby="heading-5"
                data-parent="#accordion-1"
              >
                <div className="card-body">
                  Μπορούμε να σας βοηθήσουμε και να βρούμε μαζί τη σωστή λύση
                  μέσα από προτάσεις με βάση τις δικές σας ανάγκες με την
                  επιλογή σωστών συνδυασμών, χρωμάτων και διαστάσεων.
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}

            <div className="card card-box card-sm bg-light">
              <div className="card-header" id="heading-6">
                <h2 className="card-title">
                  <a
                    className="collapsed"
                    role="button"
                    data-toggle="collapse"
                    href="#collapse-6"
                    aria-expanded="false"
                    aria-controls="collapse-6"
                  >
                    Κάθε Κυριακή είστε ανοιχτά;
                  </a>
                </h2>
              </div>
              {/* End .card-header */}
              <div
                id="collapse-6"
                className="collapse"
                aria-labelledby="heading-6"
                data-parent="#accordion-1"
              >
                <div className="card-body">
                  Όλες τις Κυριακές του χρόνου έκτος από τις επίσημες αργίες.
                </div>
                {/* End .card-body */}
              </div>
              {/* End .collapse */}
            </div>
            {/* End .card */}
          </div>
          {/* End .accordion */}
        </div>
        {/* End .container */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
