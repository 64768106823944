import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/products";
import Services from "../../Services/Services";

export default class Products extends Page {
  title = "properties";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    const pageTitle = this.getHelpers("env").get("title");
    Services.get("content").then(([contentService]) => {
      contentService
        .getAll("location")
        .then(({ data }) => {
          const locations = data;
          const location_slug = this.getCurrentLocation();
          const location_name = locations.find(
            (location) => location.slug === location_slug
          );

          this.setWindowTitle(
            `${pageTitle} - ${this.ucfirst(location_name.name)}`
          );

          this.setData({
            "default.locations": locations,
            "default.location_name": location_name,
            "default.page": 1,
            "default.wishlist": this.getWishlist(),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
    Services.get("content").then(([contentService]) => {
      contentService
        .getAll("property", {
          params: {
            fslocation: this.getPage().getParam("slug", ""),
          },
        })
        .then(({ data }) => {
          this.setData({
            "default.location_items": data,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    this.addScripts({
      "jquery.min": "/assets/js/jquery.min",
      "bootstrap.bundle.min": "/assets/js/bootstrap.bundle.min",
      "jquery.hoverIntent.min": "/assets/js/jquery.hoverIntent.min",
      "jquery.waypoints.min": "/assets/js/jquery.waypoints.min",
      "superfish.min": "/assets/js/superfish.min",
      "owl.carousel.min": "/assets/js/owl.carousel.min",
      "jquery.plugin.min": "/assets/js/jquery.plugin.min",
      "jquery.magnific-popup.min": "/assets/js/jquery.magnific-popup.min",
      "jquery.countdown.min": "/assets/js/jquery.countdown.min",
      main: "/assets/js/main",
      "demo-2": "/assets/js/demos/demo-2",
    });

    window.scrollTo({
      top: 0,
      left: 0,
    });

    this.setSeoData();
  }

  async setSeoData() {
    const account = this.getHelpers("env").getDefaultAccount();
    const repository = this.getHelpers("env").getDefaultRepository();

    const translations = await this.getHelpers("translate").scope({
      account,
      repository,
      entity: "item",
      entity_slug: "seo",
      scope: "default",
    });

    const description = translations.find(
      (translation) => translation.slug === "default-description"
    ) || { translation: "" };

    const keywords = translations.find(
      (translation) => translation.slug === "default-keywords"
    ) || { translation: "" };

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description.translation);

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", keywords.translation);
  }

  getCurrentLocation() {
    return this.getPage().getParam("slug", "");
  }

  getWishlist() {
    const wishlist = this.getHelpers("state").get("wishlist");
    if (wishlist) {
      var array = wishlist.split(",");
      return array;
    } else {
      return [];
    }
  }

  addToWishList(slug) {
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");
    // stateHelper.remove("wishlist", {
    //   domain: Env.get("domain"),
    //   path: "/",
    // });
    var array = [];
    var wishlist = stateHelper.get("wishlist", null);
    if (wishlist) {
      array = wishlist.split(",");
      if (!array.includes(slug)) {
        array.push(slug);
      }
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    } else {
      array.push(slug);
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    }

    this.setData({
      "default.wishlist": array,
    });
  }

  removeFromWishList(slug) {
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");

    var array = stateHelper.get("wishlist").split(",");
    var index = array.findIndex((el) => el === slug);
    array.splice(index, 1);
    stateHelper.set("wishlist", array.join(","), {
      domain: Env.get("domain"),
      path: "/",
    });

    this.setData({
      "default.wishlist": array,
    });
  }

  checkSearch(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search").value.length > 1) {
      window.location.href =
        devHelper + "/results/" + document.getElementById("search").value;
    }
  }
  checkSearchMobile(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search_mobile").value.length > 1) {
      window.location.href =
        devHelper +
        "/results/" +
        document.getElementById("search_mobile").value;
    }
  }
}
