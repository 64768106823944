import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();
  const visited = component.getData("default.visited", []);

  const addToWishList = (event, index, slug) => {
    event.preventDefault();
    visited[index].added = true;
    component.addToWishList(slug);
  };

  const removeFromWishList = (event, index, slug) => {
    event.preventDefault();
    visited[index].added = false;
    component.removeFromWishList(slug);
  };

  return (
    visited.length !== 0 && (
      <div className="divider-top background-gray">
        <div className="container">
          <div className="heading heading-center mb-3">
            <h2 className="title-lg">{component.ucfirst("recently-viewed")}</h2>
          </div>

          <div
            className="owl-carousel owl-simple carousel-equal-height carousel-with-shadow"
            data-toggle="owl"
            data-owl-options='{
                  "nav": false, 
                  "dots": true,
                  "margin": 20,
                  "loop": false,
                  "responsive": {
                      "0": {
                          "items":2
                      },
                      "480": {
                          "items":2
                      },
                      "768": {
                          "items":3
                      },
                      "992": {
                          "items":4
                      },
                      "1200": {
                          "items":4,
                          "nav": true,
                          "dots": false
                      }
                  }
              }'
          >
            {visited.map((item, index) => {
              return (
                <div
                  key={`item-${devHelper.getObjectValue(item, "slug")}`}
                  className="product text-center"
                >
                  <figure className="product-media">
                    <a
                      href={`/property/${devHelper.getObjectValue(
                        item,
                        "slug"
                      )}`}
                      style={{
                        height: "250px",
                        backgroundImage: `url(${component
                          .getHelpers("url")
                          .createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            devHelper.getObjectValue(item, "slug"),
                            "default",
                            "default"
                          )})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {" "}
                    </a>

                    <div className="product-action-vertical">
                      {item.added ? (
                        <a
                          href="/"
                          title="Wishlist"
                          onClick={(e) =>
                            removeFromWishList(
                              e,
                              index,
                              devHelper.getObjectValue(item, "slug")
                            )
                          }
                          className="btn-product-icon btn-expandable"
                        >
                          <i className="icon-heart"></i>
                          <span>
                            {component.ucfirst("remove-from-wishlist")}
                          </span>
                        </a>
                      ) : (
                        <a
                          href="/"
                          title="Wishlist"
                          onClick={(e) =>
                            addToWishList(
                              e,
                              index,
                              devHelper.getObjectValue(item, "slug")
                            )
                          }
                          className="btn-product-icon btn-expandable"
                        >
                          <i className="icon-heart-o"></i>
                          <span>{component.ucfirst("add-to-wishlist")}</span>
                        </a>
                      )}
                    </div>
                    {/* End .product-action-vertical */}
                  </figure>
                  {/* End .product-media */}

                  <div className="product-body">
                    <h3 className="product-title">
                      <a
                        href={`/property/${devHelper.getObjectValue(
                          item,
                          "slug"
                        )}`}
                      >
                        {devHelper.getObjectValue(item, "name")}
                      </a>
                    </h3>
                    {/* End .product-title */}
                    {item.price > 0 && (
                      <div className="product-price">
                        <span>{devHelper.getObjectValue(item, "price")} €</span>
                      </div>
                    )}
                    {/* End .product-price */}
                  </div>
                  {/* End .product-body */}
                </div>
              );
            })}
          </div>
          {/* End .owl-carousel */}
        </div>
      </div>
    )
  );
};

export default ExportDefault;
