import Env from "./Env";
import cookies from "react-cookies";

class Cookie {
  set(name, value, options) {
    const prefix = Env.get("cookies_prefix", "");
    cookies.save(`${prefix}${name}`, value, options);
    return this;
  }

  get(name, defaultValue) {
    const prefix = Env.get("cookies_prefix", "");
    defaultValue = defaultValue || null;
    return cookies.load(`${prefix}${name}`) || defaultValue;
  }

  setObject(name, value, options) {
    this.set(name, value, options);
    return this;
  }

  getObject(name, defaultValue) {
    let value = this.get(name, defaultValue);
    return value ? value : defaultValue;
  }

  remove(name, options) {
    const prefix = Env.get("cookies_prefix", "");
    cookies.remove(`${prefix}${name}`, options);
    return this;
  }

  toggle(name, value) {
    if (this.get(name)) {
      this.remove(name);
    } else {
      this.set(name, value);
    }
  }
}

class LocalStorage {
  set(name, value, options) {
    const prefix = Env.get("cookies_prefix", "");
    localStorage.setItem(`${prefix}${name}`, value);
    return this;
  }

  get(name, defaultValue) {
    const prefix = Env.get("cookies_prefix", "");
    defaultValue = defaultValue || null;
    return localStorage.getItem(`${prefix}${name}`) || defaultValue;
  }

  setObject(name, value) {
    value = JSON.stringify(value);
    this.set(name, value);
    return this;
  }

  getObject(name, defaultValue) {
    let value = this.get(name, defaultValue);
    return value ? JSON.parse(value) : defaultValue;
  }

  remove(name, options) {
    const prefix = Env.get("cookies_prefix", "");
    localStorage.removeItem(`${prefix}${name}`);
    return this;
  }

  toggle(name, value) {
    if (this.get(name)) {
      this.remove(name);
    } else {
      this.set(name, value);
    }
  }
}

const state = Env.isApp() ? new LocalStorage() : new Cookie();

export { Cookie, LocalStorage };
export default state;
