import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const valueHelper = component.getHelpers("value");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  return (
    <>
      <h2 className="title mb-1">{component.ucfirst("contact-details")}</h2>
      <div className="row">
        <div className="col">
          <div className="contact-info">
            <figure className="store-media mb-2 mb-lg-0">
              <img
                src={urlHelper.createAccountImageUrl(
                  account,
                  repository,
                  "item",
                  "gallery",
                  "default",
                  "contact"
                )}
                alt="img"
              />
            </figure>
            <ul className="contact-list mt-1">
              <li>
                <i className="icon-user"></i>
                {valueHelper.getValue("contact-name")}
              </li>
              <li>
                <i className="icon-map-marker"></i>
                {valueHelper.getValue("contact-address")}
              </li>
              <li>
                <i className="icon-phone"></i>
                <a href={`tel:${valueHelper.getValue("phone")}`}>
                  {valueHelper.getValue("phone")}
                </a>
              </li>
              <li>
                <i className="icon-phone"></i>
                <a href={`tel:${valueHelper.getValue("phone2")}`}>
                  {valueHelper.getValue("phone2")}
                </a>
              </li>
              <li>
                <i className="icon-envelope"></i>
                <a href="mailto:info@casadipatsistock.gr">
                  {valueHelper.getValue("contact-email-form")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportDefault;
