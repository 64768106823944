import Home from "./Page/Home";
import Stockhouse from "./Page/Stockhouse";
import Furniture from "./Page/Furniture";
import Contact from "./Page/Contact";
import Faq from "./Page/Faq";
import Products from "./Page/Products";
import Product from "./Page/Product";
import Wishlist from "./Page/Wishlist";
import Results from "./Page/Results";
import NotFound from "./Page/NotFound";

const Module = {
  Home,
  Stockhouse,
  Furniture,
  Contact,
  Faq,
  Products,
  Product,
  Wishlist,
  Results,
  NotFound,
};

export default Module;
