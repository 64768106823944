import ShareApi from "../../Api/Share";
import Language from "./Language";
import Env from "./Env";

class Translate {
  cache = {};

  /*
  slug(options) {
      options = options || {};

      const { 
          account,
          app,
          entity,
          entity_slug,
          scope,
          slug
      } = options;

      const lang = Language.getLang();

      if (scope) {
          return ShareApi.translation.slug(account,app,lang,entity,entity_slug,scope,slug);
      }
  }
*/

  scope(options) {
    const platformVersion = Env.get("platform_version") * 1;

    if (platformVersion === 1) {
      return this.scope1(options);
    } else {
      return this.scope2(options);
    }
  }

  scope1(options) {
    return new Promise((success, reject) => {
      options = options || {};

      const { account, app, entity, entity_slug, scope } = options;

      const lang = Language.getLang();

      if (scope) {
        const cacheKey = `${account}-${app}-${entity}-${entity_slug}-${scope}`;
        const cacheData = this.getCache(cacheKey);

        if (cacheData) {
          success(cacheData);
        } else {
          ShareApi.translation
            .scope(account, app, lang, entity, entity_slug, scope)
            .then((r) => {
              if (r.isValid()) {
                const data = r.getData();
                this.setCache(cacheKey, data);
                success(data);
              } else {
                reject();
              }
            })
            .catch((err) => {
              reject(err);
            });
        }
      } else {
        reject();
      }
    });
  }

  scope2(options) {
    return new Promise((success, reject) => {
      options = options || {};

      const { account, repository, entity, entity_slug, scope } = options;

      const lang = Language.getLang();

      if (scope) {
        const cacheKey = `${account}-${repository}-${entity}-${entity_slug}-${scope}`;
        const cacheData = this.getCache(cacheKey);

        if (cacheData) {
          success(cacheData);
        } else {
          ShareApi.content.translation
            .scope(account, repository, lang, entity, entity_slug, scope)
            .then((r) => {
              if (r.isValid()) {
                const data = r.getData();
                this.setCache(cacheKey, data);
                success(data);
              } else {
                reject();
              }
            })
            .catch((err) => {
              reject(err);
            });
        }
      } else {
        reject();
      }
    });
  }

  multiple(data, options, slugField) {
    slugField = slugField || "slug";

    return new Promise((resolve, reject) => {
      const translations = [];

      data.forEach((data) => {
        options.slug = data[slugField];
        translations.push(this.slug(options));
      });

      Promise.all(translations)
        .then((transResponses) => {
          data = data.map((data) => {
            transResponses.every((transResponse) => {
              if (transResponse.isValid()) {
                const transData = transResponse.getData();

                if (transData && transData.slug === data[slugField]) {
                  data.translation = transData;
                  return false;
                }
              }

              return true;
            });

            return data;
          });

          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setCache(key, data) {
    this.cache[key] = data;
    return this;
  }

  getCache(key) {
    return this.cache[key] || null;
  }
}

export { Translate };
export default new Translate();
