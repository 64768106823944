class Dev {
    isDefined(value) {
        return typeof value !== 'undefined';
    }

    define(value, defaultValue) {
        defaultValue = this.isDefined(defaultValue) ? defaultValue: null;
        return this.isDefined(value) ? value: defaultValue;
    }

    cloneObject(object) {
        return JSON.parse(JSON.stringify(object));
    }

    getObjectLength(object) {
        return Object.keys(object || {}).length;
    }

    traverseObject(object, path, create = false) {
        path = typeof path === 'string' ? path.split('.'): path;
        return path.reduce((object, index) => {
            if (create && !this.isDefined(object[index])) {
                object[index] = {}
            } 

            return object ? object[index]: undefined 
        },object);
    }

    hasObjectValue(object, path) {
        return this.isDefined(this.traverseObject(object,path));
    }

    getObjectValue(object, path, defaultValue) {
        defaultValue = this.isDefined(defaultValue) ? defaultValue: null;
        const value = this.traverseObject(object,path);
        return this.isDefined(value) ? value: defaultValue;
    }

    setObjectValue(object, path, value) {
        value = this.isDefined(value) ? value: null;
        path = path.split('.');
        const field = path.pop();
        const objectValue = this.traverseObject(object,path,true);

        if (typeof objectValue === 'object') {
            objectValue[field] = value;
        }

        return object;
    }

    deleteObjectValue(object, path) {
        path = path.split('.');
        const field = path.pop();
        const objectValue = this.traverseObject(object,path,true);

        if (typeof objectValue === 'object') {
            delete objectValue[field];
        }

        return object;
    }
  }

  export { Dev };
  export default new Dev();