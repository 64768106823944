import Helpers from "../../../modules/Core/Helpers/Helpers";
import Api from "../../Api/Main";
import Service from "../Service";

class Content extends Service {
  getAll(type, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      params = {},
    } = options || {};

    return new Promise((resolve, reject) => {
      Api.items(account, repository, type, {
        params,
      }).then((r) => {
        if (r.isValid()) {
          let { rows = [] } = r.getData() || {};

          rows = rows.map((row) => {
            const { _id, _values = {} } = row;
            return { ...{ _id }, ..._values };
          });

          resolve({ service: this, response: r, data: rows });
        } else {
          reject(this, r);
        }
      });
    });
  }

  getOne(type, slug, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      Api.item(account, repository, type, slug).then((r) => {
        if (r.isValid()) {
          let data = r.getData();

          if (data) {
            const { _id, _values = {} } = data;
            data = { ...{ _id }, ..._values };
          }

          resolve({ service: this, response: r, data });
        } else {
          reject(this);
        }
      });
    });
  }

  contact(data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.get("default_repository"),
    } = options || {};

    return new Promise((resolve, reject) => {
      Api.contact(account, repository, data).then((r) => {
        if (r.isValid()) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Content();
