import React from "react";
import ContactInfo from "./info";

const ExportDefault = (props) => {
  const { component } = props;

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="container">
          <div className="mt-3"></div>
          <div className="row">
            <div className="col-lg-6">
              <ContactInfo {...props} />
            </div>
            {/* End .col-lg-6 */}
            <div className="col-lg-6">
              <h2 className="title mb-1">
                {component.ucfirst("any-question")}
              </h2>
              {/* End .title mb-2 */}
              {/* <p className="mb-2">
                Χρησιμοποιήστε την παρακάτω φόρμα για να έρθετε σε επαφή με την
                ομάδα πωλήσεων
              </p> */}

              <form
                onSubmit={(e) => {
                  component.sendForm(e);
                }}
                className="contact-form mb-3"
              >
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="cname" className="sr-only">
                      {component.ucfirst("name")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cname"
                      placeholder={component.ucfirst("name")}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          component.ucfirst("enter-your-name")
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}

                  <div className="col-sm-6">
                    <label htmlFor="cemail" className="sr-only">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="cemail"
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          component.ucfirst("enter-your-email")
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      placeholder="Email *"
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}
                </div>
                {/* End .row */}

                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="cphone" className="sr-only">
                      {component.ucfirst("phone")}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          component.ucfirst("enter-your-phone")
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      id="cphone"
                      placeholder={component.ucfirst("phone")}
                      required
                    />
                  </div>
                  {/* End .col-sm-6 */}

                  <div className="col-sm-6">
                    <label htmlFor="csubject" className="sr-only">
                      {component.ucfirst("subject")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="csubject"
                      placeholder={component.ucfirst("subject")}
                    />
                  </div>
                  {/* End .col-sm-6 */}
                </div>
                {/* End .row */}

                <label htmlFor="cmessage" className="sr-only">
                  {component.ucfirst("message")}
                </label>
                <textarea
                  className="form-control"
                  cols="30"
                  rows="4"
                  id="cmessage"
                  required
                  placeholder={component.ucfirst("message")}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      component.ucfirst("enter-your-message")
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                ></textarea>

                <button
                  type="submit"
                  className="btn btn-outline-primary-2 btn-minwidth-sm"
                >
                  <span>{component.ucfirst("submit")}</span>
                  <i className="icon-long-arrow-right"></i>
                </button>
              </form>
              {/* End .contact-form */}
            </div>
            {/* End .col-lg-6 */}
          </div>
          {/* End .row */}
          {/* <hr className="mt-4 mb-5" /> */}

          {/* <div className="stores mb-4 mb-lg-5">
            <h2 className="title text-center mb-3">Τα Καταστήματα</h2>

            <div className="row">
              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "kifisia"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>
                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">Casa Di Patsi Κηφισιά</h3>
                        <address>Λ. Κηφισίας 188</address>
                        <div>
                          <a href="tel:+302108082666">+302108082666</a>
                          <p>email: info1@casadipatsi.gr</p>
                        </div>
                        <h4 className="store-subtitle">Ώρες Καταστήματος:</h4>
                        <div>Δευτέρα,Τετάρτη,Σάββατο 9:30π.μ.–5:00μ.μ.</div>
                        <div>Τρίτη,Πέμπτη,Παρασκευή 9:30π.μ.–9:00μ.μ.</div>
                        <div>Κυριακή Κλειστά</div>
                        <a
                          href="https://www.google.com/maps/place/Casa+Di+Patsi/@38.0661975,23.8079436,17z/data=!4m5!3m4!1s0x14a19f27e2a4835f:0x110e4fc6fca84b24!8m2!3d38.0661933!4d23.8101323"
                          className="btn btn-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "alimos"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>
                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">Casa Di Patsi Άλιμος</h3>
                        <address>Λ. Ποσειδώνος 75</address>
                        <div>
                          <a href="tel:+302109810311">+302109810311</a>
                          <p>email: info2@casadipatsi.gr</p>
                        </div>
                        <h4 className="store-subtitle">Ώρες Καταστήματος:</h4>
                        <div>Δευτέρα,Τετάρτη,Σάββατο 9:30π.μ.–5:00μ.μ.</div>
                        <div>Τρίτη,Πέμπτη,Παρασκευή 9:30π.μ.–9:00μ.μ.</div>
                        <div>Κυριακή Κλειστά</div>
                        <a
                          href="https://www.google.com/maps/place/Casa+di+Patsi/@37.9045548,23.7169556,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1be632153af01:0xfc7bba12e2161ad7!8m2!3d37.9045548!4d23.7191443"
                          rel="noreferrer"
                          className="btn btn-link"
                          target="_blank"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "agia_paraskevi"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>

                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">
                          Casa Di Patsi Αγία Παρασκευή
                        </h3>
                        <address>Λ. Μεσογείων 515</address>
                        <div>
                          <a href="tel:+302106015669">+302106015669</a>
                          <p>email: info3@casadipatsi.gr</p>
                        </div>
                        <h4 className="store-subtitle">Ώρες Καταστήματος:</h4>
                        <div>Δευτέρα,Τετάρτη,Σάββατο 9:30π.μ.–5:00μ.μ.</div>
                        <div>Τρίτη,Πέμπτη,Παρασκευή 9:30π.μ.–9:00μ.μ.</div>
                        <div>Κυριακή Κλειστά</div>
                        <a
                          rel="noreferrer"
                          href="https://www.google.com/maps/place/Casa+Di+Patsi+%CE%91%CE%B3%CE%AF%CE%B1+%CE%A0%CE%B1%CF%81%CE%B1%CF%83%CE%BA%CE%B5%CF%85%CE%AE/@38.0133166,23.8317893,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1990daaaaaaab:0xf4b3153567c9f848!8m2!3d38.0133204!4d23.8339807"
                          className="btn btn-link"
                          target="_blank"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "stockhouse"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>
                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">
                          Casa Di Patsi Stockhouse
                        </h3>
                        <address>Ζακύνθου 2 και Οδυσσέως</address>
                        <div>
                          <a href="tel:+302102850233">+302102850233</a>
                          <p>email: info@casadipatsistock.gr</p>
                        </div>
                        <h4 className="store-subtitle">Ώρες Καταστήματος:</h4>
                        <div>Δευτέρα,Τετάρτη,Σάββατο 9:30π.μ.–4:30μ.μ.</div>
                        <div>Τρίτη,Πέμπτη,Παρασκευή 9:30π.μ.–2:30μ.μ.</div>
                        <div style={{ textAlign: "right" }}>
                          5:00μ.μ.–8:00μ.μ.
                        </div>
                        <div>Κυριακή 10:00π.μ.–2:30μ.μ.</div>
                        <a
                          href="https://www.google.com/maps/place/CASA+DI+PATSI+Stockhouse/@38.0678071,23.7531027,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1a1f89df1a515:0xaed40861765ff4d9!8m2!3d38.0678116!4d23.7552822"
                          rel="noreferrer"
                          className="btn btn-link"
                          target="_blank"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "matt_royal"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>

                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">MATT ROYAL</h3>
                        <address>Λ. Κηφισίας 190</address>
                        <div>
                          <a href="tel:+302108010706">+302108010706</a>
                          <p>email: kifisia@matt-royal.gr</p>
                        </div>
                        <h4 className="store-subtitle">Ώρες Καταστήματος:</h4>
                        <div>Δευτέρα,Τετάρτη,Σάββατο 9:30π.μ.–5:00μ.μ.</div>
                        <div>Τρίτη,Πέμπτη,Παρασκευή 9:30π.μ.–9:00μ.μ.</div>
                        <div>Κυριακή Κλειστά</div>
                        <a
                          rel="noreferrer"
                          href="https://www.google.com/maps/place/MATT+ROYAL+%7C+%CE%A7%CE%B5%CE%B9%CF%81%CE%BF%CF%80%CE%BF%CE%AF%CE%B7%CF%84%CE%B1+%CE%A3%CF%84%CF%81%CF%8E%CE%BC%CE%B1%CF%84%CE%B1+%26+%CE%A0%CF%81%CE%BF%CF%8A%CF%8C%CE%BD%CF%84%CE%B1+%CE%8E%CF%80%CE%BD%CE%BF%CF%85/@38.0666229,23.8103812,15z/data=!4m5!3m4!1s0x0:0x8abda2d89b875beb!8m2!3d38.0666111!4d23.8104338"
                          className="btn btn-link"
                          target="_blank"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="store">
                  <div className="row">
                    <div className="col-sm-5 col-xl-6">
                      <figure className="store-media mb-2 mb-lg-0">
                        <img
                          src={urlHelper.createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "gallery",
                            "default",
                            "kriti"
                          )}
                          alt="img"
                        />
                      </figure>
                    </div>

                    <div className="col-sm-7 col-xl-6">
                      <div className="store-content">
                        <h3 className="store-title">
                          Casa Di Patsi Ηράκλειο Κρήτης
                        </h3>
                        <address>Λεωφ. Δημοκρατίας 38</address>
                        <div>
                          <a href="tel:+302810346496">+302810346496</a>
                          <p>email: crete@casadipatsi.gr</p>
                        </div>
                        <h4 className="store-subtitle">Ώρες Καταστήματος:</h4>
                        <div>Δευτέρα,Τετάρτη,Σάββατο 9:30π.μ.–5:00μ.μ.</div>
                        <div>Τρίτη,Πέμπτη,Παρασκευή 9:30π.μ.–9:00μ.μ.</div>
                        <div>Κυριακή Κλειστά</div>
                        <a
                          href="https://www.google.com/maps/place/%CE%9B%CE%B5%CF%89%CF%86.+%CE%94%CE%B7%CE%BC%CE%BF%CE%BA%CF%81%CE%B1%CF%84%CE%AF%CE%B1%CF%82+38,+%CE%97%CF%81%CE%AC%CE%BA%CE%BB%CE%B5%CE%B9%CE%BF+713+06/@35.3299181,25.1380395,17z/data=!4m5!3m4!1s0x149a5981031786ab:0xe9c7c58621a2688e!8m2!3d35.3299302!4d25.1378808"
                          rel="noreferrer"
                          className="btn btn-link"
                          target="_blank"
                        >
                          <span>Δείτε στο χάρτη</span>
                          <i className="icon-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* End .container */}
        {/* <div id="custom_map" className="map"></div> */}
        {/* End #map */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
