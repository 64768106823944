class Template {
    scope = '';

    setScope(scope) {
        this.scope = scope;
        return this;
    }

    getScope(defaultValue) {        
        return this.scope || defaultValue;
    }
}

export { Template }
export default new Template();
