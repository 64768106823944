import Api from "../Core/Helpers/Api";
import Language from "../Core/Helpers/Language";

const shareApi = Api.create("share");

const ExportDefault = {
  value: {
    account: function (account, options) {
      return shareApi.get(`value/account/${account}`, options);
    },
    app: function (account, app, options) {
      return shareApi.get(`value/account/${account}/app/${app}`, options);
    },
    slug: function (account, app, slug, options) {
      return shareApi.get(
        `value/account/${account}/app/${app}/slug/${slug}`,
        options
      );
    },
  },
  literal: {
    app: function (account, lang, app, options) {
      return shareApi.get(
        `literal/account/${account}/lang/${lang}/app/${app}`,
        options
      );
    },
    /*
    slug: function(account, lang, app, slug, options) {
      return shareApi.get(`literal/account/${account}/lang/${lang}/app/${app}/slug/${slug}`,options);
    },
    */
  },
  translation: {
    scope: function (account, app, lang, entity, entity_slug, scope, options) {
      return shareApi.get(
        `translation/account/${account}/app/${app}/lang/${lang}/entity/${entity}/slug/${entity_slug}/scope/${scope}`,
        options
      );
    },
    slug: function (
      account,
      app,
      lang,
      entity,
      entity_slug,
      scope,
      slug,
      options
    ) {
      return shareApi.get(
        `translation/account/${account}/app/${app}/lang/${lang}/entity/${entity}/slug/${entity_slug}/scope/${scope}/slug/${slug}`,
        options
      );
    },
  },
  file: {
    scope: function (account, app, entity, eslug, scope, options) {
      return shareApi.get(
        `file/account/${account}/app/${app}/entity/${entity}/eslug/${eslug}/scope/${scope}`,
        options
      );
    },
  },
  language: {
    app: function (account, app, options) {
      return shareApi.get(`language/account/${account}/app/${app}`, options);
    },
  },
  content: {
    values: function (account, repository, options) {
      return shareApi.get(
        `content/account/${account}/repository/${repository}/values`,
        options
      );
    },
    languages: function (account, repository, options) {
      return shareApi.get(
        `content/account/${account}/repository/${repository}/languages`,
        options
      );
    },
    literals: function (account, repository, lang, options) {
      options = options || {};
      const { params = {} } = options;
      params.lang = Language.getLang();
      options.params = params;

      return shareApi.get(
        `content/account/${account}/repository/${repository}/lang/${lang}/literals`,
        options
      );
    },
    translation: {
      scope: function (
        account,
        repository,
        lang,
        entity,
        entity_slug,
        scope,
        options
      ) {
        return shareApi.get(
          `content/account/${account}/repository/${repository}/lang/${lang}/translation/entity/${entity}/slug/${entity_slug}/scope/${scope}`,
          options
        );
      },
      slug: function (
        account,
        repository,
        lang,
        entity,
        entity_slug,
        scope,
        slug,
        options
      ) {
        return shareApi.get(
          `content/account/${account}/repository/${repository}/lang/${lang}/translation/entity/${entity}/slug/${entity_slug}/scope/${scope}/slug/${slug}`,
          options
        );
      },
    },
    file: {
      scope: function (account, repository, entity, eslug, scope, options) {
        return shareApi.get(
          `content/account/${account}/repository/${repository}/file/entity/${entity}/eslug/${eslug}/scope/${scope}`,
          options
        );
      },
    },
    set: function (account, repository, slug, options) {
      return shareApi.get(
        `content/account/${account}/repository/${repository}/set/${slug}/items`,
        options
      );
    },
    categories: function (account, repository, options) {
      return shareApi.get(
        `content/account/${account}/repository/${repository}/categories`,
        options
      );
    },
  },
};

export default ExportDefault;
