import { Component } from "../../../modules/Core/Component";
import template from "../../templates/default/widgets/facebook-chat";

export default class GoogleAnalytics extends Component {
  template = template;

  onLoad(data) {
    super.onLoad(data);

    if (!this.getHelpers("env").isLocal()) {
      this.getPage().addScripts(
        {
          googletagmanager:
            "https://www.googletagmanager.com/gtag/js?id=UA-49671519-1",
        },
        true,
        true,
        (js) => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "UA-49671519-1");
        }
      );
    }
  }
}
