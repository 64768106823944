import Dev from "./Dev";

class Value {
  values = [];

  set(values) {
    this.values = values;
    return this;
  }

  get() {
    return this.values || [];
  }

  getValue(slug, defaultValue, values = null) {
    defaultValue = Dev.define(defaultValue, null);
    values = values ? values : this.get();
    values = values || [];

    const { value = defaultValue } =
      values.find((value) => value.slug === slug) || {};

    return value;
  }
}

export { Value };
export default new Value();
