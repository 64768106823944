import Dev from "./Dev";

class Const {
  values = {};

  set(name, value) {
    this.values[name] = value;
    return this;
  }

  get(name, defaultValue) {
    defaultValue = Dev.define(defaultValue);
    return Dev.define(this.values[name], defaultValue);
  }
}

export { Const };
export default new Const();
