import React from "react";
const ExportDefault = (props) => {
  const { component } = props;
  const phone = component.getHelpers("value").getValue("phone");
  const locations = component.getData("default.locations", []);
  const current_page = component.getData("default.page", null);
  const wishlist = component.getData("default.wishlist", []);

  return (
    <header className="header header-2 header-intro-clearance">
      <div className="header-middle">
        <div className="container">
          <div className="header-left">
            <button className="mobile-menu-toggler">
              <span className="sr-only">Toggle mobile menu</span>
              <i className="icon-bars"></i>
            </button>

            <a href="/" className="logo">
              <img src="/logo.png" alt="Molla Logo" width="200" height="100" />
            </a>
          </div>
          {/* End .header-left */}
          <div className="header-center">
            <div className="header-search header-search-extended header-search-visible header-search-no-radius d-none d-lg-block">
              <a href="/" className="search-toggle" role="button">
                <i className="icon-search"></i>
              </a>
              <form onSubmit={(e) => component.checkSearch(e)} method="get">
                <div className="header-search-wrapper search-wrapper-wide">
                  <label htmlFor="q" className="sr-only">
                    {component.ucfirst("search")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="q"
                    id="search"
                    placeholder={component.ucfirst("search-property")}
                  />
                  <button className="btn btn-primary" type="submit">
                    <i className="icon-search"></i>
                  </button>
                </div>
                {/* End .header-search-wrapper */}
              </form>
            </div>
            {/* End .header-search */}
          </div>

          <div id="header_contact_info" className="header-right">
            <ul id="info">
              <li>
                <a href={`tel:${phone}`} className="tel_number" id="phone">
                  <i className="icon-phone-square"></i>
                  {phone}
                </a>
              </li>
            </ul>
            <div className="wishlist">
              <a href="/wishlist" title="Wishlist">
                <div className="icon">
                  <i className="icon-heart-o"></i>
                  <span id="wishlist_cicle" className="wishlist-count badge">
                    {wishlist.length}
                  </span>
                </div>
                <p>{component.ucfirst("wishlist")}</p>
              </a>
            </div>
            {/* End .compare-dropdown */}
          </div>
          {/* End .header-right */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-middle */}
      <div className="mt-1" id="mobile_contact_info">
        <div className="text-center">
          <ul className="mb-0">
            <li
              className="li_xs_fix"
              style={{
                margin: "0px 3px",
              }}
            >
              <a href={`tel:${phone}`} style={{ color: "#696969" }}>
                <i
                  className="icon-phone"
                  style={{ color: "red", fontSize: "20px" }}
                ></i>
                {phone}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="header-bottom sticky-header">
        <div id="my_cont" className="container">
          <div className="header-left"></div>
          <div className="header-center">
            <nav className="main-nav">
              <ul className="menu sf-arrows">
                <li
                  className={
                    current_page === 0
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                >
                  <a href="/" className="text-uppercase">
                    {component.ucfirst("home-title")}
                  </a>
                </li>
                <li
                  className={
                    current_page === 1 ? "active nav_liks" : "nav_liks"
                  }
                >
                  <a
                    href="/"
                    onClick={(e) => e.preventDefault()}
                    className="text-uppercase"
                  >
                    {component.ucfirst("locations-title")}
                  </a>

                  <ul>
                    {locations.map((location) => {
                      const { slug, name } = location;

                      return (
                        <li key={`location-${slug}`}>
                          <a
                            href={`/location/${slug}`}
                            className="location-name text-capitalize"
                          >
                            {name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>

                <li
                  className={
                    current_page === 4
                      ? "megamenu-container active nav_liks"
                      : "megamenu-container nav_liks"
                  }
                >
                  <a href="/contact-us" className="text-uppercase">
                    {component.ucfirst("contact-us-title")}
                  </a>
                </li>
              </ul>
              {/* End .menu */}
            </nav>
            {/* End .main-nav */}
          </div>
          {/* End .header-center */}
        </div>
        {/* End .container */}
      </div>
      {/* End .header-bottom */}
    </header>
  );
};

export default ExportDefault;
