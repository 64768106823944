import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const envHelper = component.getHelpers("env");
  const urlHelper = component.getHelpers("url");
  const account = envHelper.getDefaultAccount();
  const repository = envHelper.getDefaultRepository();

  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById("custom_map").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <div className="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mb-3 mb-lg-0">
                <h2 className="title">
                  Τι είναι το <span className="text-primary">Stockhouse</span>;
                </h2>
                {/* End .title */}
                <p className="lead mb-1">
                  Στο κατάστημα <span className="text-primary">Stockhouse</span>{" "}
                  θα βρείτε έπιπλα για το σπίτι σας με την ποιότητα και με την
                  εγγύηση της Casa di Patsi σε πραγματικά καλές τιμές.
                </p>
                {/* End .lead text-primary */}
                <p className="mb-2">
                  Πώς γίνεται αυτό; Κάθε τόσο ανανεώνουμε τα έπιπλα στα
                  καταστήματά μας με νέα κομμάτια, έτσι τα εκθέματα αυτά φεύγουν
                  και βρίσκουν τη θέση στο Stockhouse μέχρι κάποιος από εσάς να
                  βρει την ευκαιρία που του ταιριάζει! Για τον λόγο αυτό τα
                  προϊόντα στο κατάστημά μας ανανεώνονται συνεχώς.
                  <br />
                  <br /> Επίσης θα βρείτε και προϊόντα τα οποία μπορείτε να
                  παραγγείλετε εξαρχής με δυνατότητα επιλογής χρωμάτων και
                  διαστάσεων όπως καναπέδες, κρεβάτια και τραπέζια σε ειδικές
                  τιμές.
                  <br />
                  <br /> Το κατάστημα Stockhouse βρίσκεται μαζί με τα κεντρικά
                  μας γραφεία,
                  <b> Ζακύνθου 2 και Οδυσσέως, Μεταμόρφωση </b>
                  είμαστε <b>καθημερινά ανοιχτά και Κυριακή</b>.
                </p>

                <button
                  // href="#custom_map"
                  onClick={() => handleClick()}
                  className="btn btn-sm btn-minwidth btn-outline-primary-2"
                >
                  <span>Βρες το κατάστημα</span>
                  <i className="icon-long-arrow-right"></i>
                </button>
              </div>
              {/* End .col-lg-5 */}
              <div className="col-lg-6 offset-lg-1">
                <div className="about-images">
                  <img
                    style={{ width: "436px", height: "330px" }}
                    src={urlHelper.createAccountImageUrl(
                      account,
                      repository,
                      "item",
                      "gallery",
                      "default",
                      "skock-house-2"
                    )}
                    alt=""
                    className="about-img-front"
                  />
                  <img
                    style={{ width: "276px", height: "320px" }}
                    src={urlHelper.createAccountImageUrl(
                      account,
                      repository,
                      "item",
                      "gallery",
                      "default",
                      "skock-house-1"
                    )}
                    alt=""
                    className="about-img-back"
                  />
                </div>

                <div className="about-images-mobile">
                  <img
                    style={{ width: "276px", height: "320px" }}
                    src={urlHelper.createAccountImageUrl(
                      account,
                      repository,
                      "item",
                      "gallery",
                      "default",
                      "skock-house-1"
                    )}
                    alt=""
                    // className="about-img-back"
                  />
                  {/* <a
                    href="#"
                    style={{
                      height: "330px",
                      width: "436px",
                      backgroundImage: `url(${urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "skock-house-2"
                      )})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  ></a> */}
                </div>
                {/* End .about-images */}
              </div>
              {/* End .col-lg-6 */}
            </div>
            {/* End .row */}
          </div>
          {/* End .container */}
        </div>
        {/* End .bg-light-2 pt-6 pb-6 */}

        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="brands-text">
                <h2 className="title">
                  Οι κορυφαίες μάρκες σχεδιασμού παγκοσμίως σε έναν προορισμό.
                </h2>
                {/* End .title */}
              </div>
              {/* End .brands-text */}
            </div>
            {/* End .col-lg-5 */}
            <div className="col-lg-7">
              <div className="brands-display">
                <div className="row justify-content-center">
                  <div className="col-6 col-sm-4" style={{ padding: "50px" }}>
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "logo-1"
                      )}
                      alt="Brand"
                    />
                  </div>
                  {/* End .col-sm-4 */}

                  <div className="col-6 col-sm-4" style={{ padding: "50px" }}>
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "logo-2"
                      )}
                      alt="Brand"
                    />
                  </div>
                  {/* End .col-sm-4 */}

                  <div className="col-6 col-sm-4" style={{ padding: "50px" }}>
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "logo-3"
                      )}
                      alt="Brand"
                    />
                  </div>
                  {/* End .col-sm-4 */}

                  <div className="col-6 col-sm-4" style={{ padding: "50px" }}>
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "logo-4"
                      )}
                      alt="Brand"
                    />
                  </div>
                  {/* End .col-sm-4 */}

                  <div className="col-6 col-sm-4" style={{ padding: "50px" }}>
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "logo-5"
                      )}
                      alt="Brand"
                    />
                  </div>
                  {/* End .col-sm-4 */}

                  <div className="col-6 col-sm-4" style={{ padding: "50px" }}>
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "logo-6"
                      )}
                      alt="Brand"
                    />
                  </div>
                  {/* End .col-sm-4 */}

                  <div className="col-6 col-sm-4" style={{ padding: "50px" }}>
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "logo-7"
                      )}
                      alt="Brand"
                    />
                  </div>
                  {/* End .col-sm-4 */}

                  <div className="col-6 col-sm-4" style={{ padding: "50px" }}>
                    <img
                      src={urlHelper.createAccountImageUrl(
                        account,
                        repository,
                        "item",
                        "gallery",
                        "default",
                        "logo-8"
                      )}
                      alt="Brand"
                    />
                  </div>
                  {/* End .col-sm-4 */}
                </div>
                {/* End .row */}
              </div>
              {/* End .brands-display */}
            </div>
            {/* End .col-lg-7 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      <div id="custom_map" className="map"></div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
