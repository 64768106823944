import React from "react";
// import FacebookChat from "../../../Components/Widget/FacebookChat";
import Translate from "../../../../modules/Core/Components/Translate";
import GoogleAnalytics from "../../../Components/Widget/GoogleAnalytics";
// import ContactInfo from "./contact/info";

const ExportDefault = (props) => {
  const { component } = props;
  // const envHelper = component.getHelpers("env");
  // const urlHelper = component.getHelpers("url");
  const valueHelper = component.getHelpers("value");
  // const account = envHelper.getDefaultAccount();
  // const repository = envHelper.getDefaultRepository();
  const phone = valueHelper.getValue("phone");
  const facebookLink = valueHelper.getValue("facebook-link");
  const instagramLink = valueHelper.getValue("instagram-link");

  return (
    <footer className="footer footer-2">
      {/* <FacebookChat /> */}

      {/* <div
        className="footer-newsletter bg-image"
        style={{
          backgroundImage: `url(${urlHelper.createAccountImageUrl(
            account,
            repository,
            "item",
            "gallery",
            "default",
            "end"
          )})`,
        }}
      >
        <div className="container">
          <div className="heading text-center newsletter">
            <h3 className="title">Ενημερωτικό Δελτίο</h3>
            <p className="title-desc">
              Κάνε εγγραφή και μάθε πρώτος για τις προσφορές μας
            </p>
          </div>
          <div className="row">
            <div
              id="mc_embed_signup"
              className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
            >
              <form
                action="https://casadipatsi.us4.list-manage.com/subscribe/post?u=eedb0610666ee526462c8eab1&amp;id=de0edcf0ff"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div className="input-group">
                  <input
                    type="email"
                    name="EMAIL"
                    className="form-control"
                    id="mce-EMAIL"
                    placeholder="Διεύθυνση email"
                    required
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                    >
                      <span>ΕΓΓΡΑΦΗ</span>
                      <i className="icon-long-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="widget _widget-about">
                <img
                  src="/logo.png"
                  className="footer-logo"
                  alt="Footer Logo"
                  width="150"
                  height="50"
                />
                <p className="mb-3">
                  <Translate
                    entity="literal"
                    eslug="about-us-short"
                    scope="default"
                    slug="default"
                    auto
                  />
                </p>

                <div className="widget-about-info">
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <span className="widget-about-title">
                        {component.ucfirst("any-question-call-us")}
                      </span>
                      <a className="tel_number" href={`tel:${phone}`}>
                        {phone}
                      </a>
                    </div>
                    {/* End .col-sm-6 */}
                  </div>
                  {/* End .row */}
                </div>
                {/* End .widget-about-info */}
              </div>
              {/* End .widget about-widget */}
            </div>
            {/* End .col-sm-12 col-lg-3 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .footer-middle */}

      <div className="footer-bottom">
        <div className="container">
          {(facebookLink || instagramLink) && (
            <div className="social-icons">
              <span className="social-label">Social Media</span>

              {facebookLink && (
                <a
                  href={facebookLink}
                  className="social-icon"
                  target="_blank"
                  rel="noreferrer"
                  title="Facebook"
                >
                  <i className="icon-facebook-f"></i>
                </a>
              )}
              {instagramLink && (
                <a
                  href={instagramLink}
                  className="social-icon"
                  target="_blank"
                  rel="noreferrer"
                  title="Instagram"
                >
                  <i className="icon-instagram"></i>
                </a>
              )}
            </div>
          )}
          {/* End .soial-icons */}
        </div>
        {/* End .container */}
      </div>
      {/* End .footer-bottom */}
      <GoogleAnalytics />
    </footer>
  );
};

export default ExportDefault;
