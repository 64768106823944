import React from "react";
import Translate from "../../../../../modules/Core/Components/Translate";

const ExportDefault = (props) => {
  const { component } = props;
  const item = component.getData("default.item", null);
  const devHelper = component.getHelpers("dev");
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  const added = component.getData("default.added", false);
  const images = component.getData("default.images", []);

  // const [value, setValue] = React.useState("#");
  // const [oldPrice, setPrice] = React.useState(false);
  // const [newPrice, setNewPrice] = React.useState(false);
  // const { has_sizes = false } = item || {};

  // const updatePrice = (event) => {
  //   var slug = event.target.value;
  //   setValue(slug);
  //   if (slug === "#") {
  //     setPrice(false);
  //     setNewPrice(false);
  //   } else {
  //     item.item_sizes.every((size) => {
  //       if (size.slug === slug) {
  //         var prices = size.extra.split(",");
  //         setPrice(prices[0]);
  //         setNewPrice(prices[1]);
  //         return false;
  //       }
  //       return true;
  //     });
  //   }
  // };
  const colorChange = (event, slug) => {
    event.preventDefault();
    item.item_colors.forEach((color, index) => {
      if (color.slug === slug) {
        color.selected = true;
      } else {
        color.selected = false;
      }
    });
  };
  const addToWishList = (event, slug) => {
    event.preventDefault();
    component.addToWishList(slug);
  };

  const removeFromWishList = (event, slug) => {
    event.preventDefault();
    component.removeFromWishList(slug);
  };

  const openPopup = (event) => {
    event.preventDefault();
    document.getElementById("myModal").style.display = "block";
  };

  // const getFileSize = (props) => {
  //   const { component } = props;
  //   const slug = component.getParam("slug");
  //   const envHelper = component.getHelpers("env");
  //   const urlHelper = component.getHelpers("url");
  //   const account = envHelper.getDefaultAccount();
  //   const repository = envHelper.getDefaultRepository();

  //   return urlHelper.createAccountFileUrl(
  //     account,
  //     repository,
  //     "item",
  //     slug,
  //     "pdf",
  //     "sizes"
  //   );
  // };

  const handleClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.scrollTo({
        top: document.getElementById("main_image").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <React.Fragment>
      <div>
        <div className="mt-3"></div>
        {item && (
          <div className="container">
            <div className="product-details-top mb-2">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-gallery">
                    <figure className="product-main-image">
                      <img
                        id="product-zoom"
                        src={component
                          .getHelpers("url")
                          .createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            devHelper.getObjectValue(item, "slug"),
                            "default",
                            "default"
                          )}
                        alt="product img"
                      />

                      <button
                        id="btn-product-gallery"
                        className="btn-product-gallery"
                      >
                        <i className="icon-arrows"></i>
                      </button>
                    </figure>
                    {/* End .product-main-image */}

                    <div
                      id="product-zoom-gallery"
                      className="product-image-gallery"
                    >
                      {images.map((img) => (
                        <a
                          href="/"
                          onClick={(e) => handleClick(e)}
                          key={devHelper.getObjectValue(img, "slug")}
                          className="product-gallery-item"
                          style={{
                            backgroundImage: `url(${component
                              .getHelpers("url")
                              .createAccountImageUrl(
                                account,
                                repository,
                                "item",
                                devHelper.getObjectValue(img, "entity_slug"),
                                "default",
                                devHelper.getObjectValue(img, "slug")
                              )})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                          data-image={component
                            .getHelpers("url")
                            .createAccountImageUrl(
                              account,
                              repository,
                              "item",
                              devHelper.getObjectValue(img, "entity_slug"),
                              "default",
                              devHelper.getObjectValue(img, "slug")
                            )}
                          data-zoom-image={component
                            .getHelpers("url")
                            .createAccountImageUrl(
                              account,
                              repository,
                              "item",
                              devHelper.getObjectValue(img, "entity_slug"),
                              "default",
                              devHelper.getObjectValue(img, "slug")
                            )}
                        >
                          {" "}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 ">
                  <div className="product-details-action mb-2">
                    <button
                      className="btn-cart"
                      onClick={(e) => openPopup(e)}
                      style={{ marginBottom: "10px" }}
                    >
                      <span>{component.ucfirst("inquiry")}</span>
                    </button>
                    {added ? (
                      <a
                        href="/"
                        title="Wishlist"
                        onClick={(e) =>
                          removeFromWishList(
                            e,
                            devHelper.getObjectValue(item, "slug")
                          )
                        }
                        style={{ marginLeft: "5px", marginTop: "-10px" }}
                      >
                        <i
                          className="icon-heart"
                          style={{ marginRight: "5px" }}
                        ></i>
                        <span>{component.ucfirst("remove-from-wishlist")}</span>
                      </a>
                    ) : (
                      <a
                        href="/"
                        title="Wishlist"
                        onClick={(e) =>
                          addToWishList(
                            e,
                            devHelper.getObjectValue(item, "slug")
                          )
                        }
                        style={{ marginLeft: "5px", marginTop: "-10px" }}
                      >
                        <i
                          className="icon-heart-o"
                          style={{ marginRight: "5px" }}
                        ></i>
                        <span>{component.ucfirst("add-to-wishlist")}</span>
                      </a>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 mb-3">
                  <Translate
                    entity="item"
                    eslug={devHelper.getObjectValue(item, "slug")}
                    scope="default"
                    slug="attributes"
                    auto
                  />
                </div>

                <div className="col-lg-12 mb-1">
                  <div className="product-details">
                    <h1
                      className="product-title"
                      style={{ fontSize: "30px", marginBottom: "10px" }}
                    >
                      {component.ucfirst(
                        devHelper.getObjectValue(item, "name")
                      )}
                    </h1>

                    {/* End .product-title */}
                    {item.price > 0 && (
                      <div className="product-price mb-2">
                        <span>{devHelper.getObjectValue(item, "price")} €</span>
                      </div>
                    )}
                    <p className="mb-2">
                      <Translate
                        entity="item"
                        eslug={devHelper.getObjectValue(item, "slug")}
                        scope="default"
                        slug="description"
                        auto
                      />
                    </p>

                    <p className="mb-2">
                      <Translate
                        entity="item"
                        eslug={devHelper.getObjectValue(item, "slug")}
                        scope="default"
                        slug="features"
                        auto
                      />
                    </p>

                    {item.item_colors && item.item_colors.length !== 0 && (
                      <div className="details-filter-row details-row-size mb-2">
                        <label>Χρώματα:</label>
                        <div className="product-nav product-nav-dots">
                          {item.item_colors.map((color) => (
                            <a
                              key={
                                "color-" +
                                devHelper.getObjectValue(color, "slug")
                              }
                              href="/"
                              className={
                                devHelper.getObjectValue(
                                  color,
                                  "selected",
                                  false
                                )
                                  ? "active"
                                  : ""
                              }
                              onClick={(e) =>
                                colorChange(
                                  e,
                                  devHelper.getObjectValue(color, "slug")
                                )
                              }
                              style={{
                                background:
                                  "#" +
                                  devHelper.getObjectValue(color, "value"),
                              }}
                            >
                              <span className="sr-only">
                                {devHelper.getObjectValue(color, "slug")}
                              </span>
                            </a>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* End .details-filter-row */}

                    <div className="product-details-action mb-2">
                      <button
                        className="btn-cart"
                        onClick={(e) => openPopup(e)}
                        style={{ marginBottom: "10px" }}
                      >
                        <span>{component.ucfirst("inquiry")}</span>
                      </button>
                      {added ? (
                        <a
                          href="/"
                          title="Wishlist"
                          onClick={(e) =>
                            removeFromWishList(
                              e,
                              devHelper.getObjectValue(item, "slug")
                            )
                          }
                          style={{ marginLeft: "5px", marginTop: "-10px" }}
                        >
                          <i
                            className="icon-heart"
                            style={{ marginRight: "5px" }}
                          ></i>
                          <span>
                            {component.ucfirst("remove-from-wishlist")}
                          </span>
                        </a>
                      ) : (
                        <a
                          href="/"
                          title="Wishlist"
                          onClick={(e) =>
                            addToWishList(
                              e,
                              devHelper.getObjectValue(item, "slug")
                            )
                          }
                          style={{ marginLeft: "5px", marginTop: "-10px" }}
                        >
                          <i
                            className="icon-heart-o"
                            style={{ marginRight: "5px" }}
                          ></i>
                          <span>{component.ucfirst("add-to-wishlist")}</span>
                        </a>
                      )}
                    </div>
                    {/* End .product-details-action */}
                  </div>
                  {/* End .product-details */}
                </div>
                {/* End .col-md-6 */}
              </div>
              {/* End .row */}
            </div>
            {/* End .product-details-top */}
          </div>
        )}
        {/* End .container */}
      </div>
      <div id="myModal" className="modal">
        <div className="modal-content bg-white">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="text-center">
                <i
                  className="icon-close close-btn"
                  onClick={() => {
                    document.getElementById("myModal").style.display = "none";
                  }}
                ></i>
                <img
                  src="/logo.png"
                  className="logo"
                  alt="logo"
                  width="150"
                  height="30"
                />

                <h2 className="title mb-2">
                  {component.ucfirst("question-for-property")}:{" "}
                  {component.ucfirst(devHelper.getObjectValue(item, "name"))}
                </h2>
                {/* End .title mb-2 */}
                {/* <p className="mb-2">
                  Χρησιμοποιήστε την παρακάτω φόρμα για να έρθετε σε επαφή με
                  την ομάδα πωλήσεων
                </p> */}

                <form
                  onSubmit={(e) => {
                    component.sendForm(e);
                  }}
                  className="contact-form mb-3"
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cname" className="sr-only">
                        {component.ucfirst("name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="cname"
                        placeholder={component.ucfirst("name")}
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            component.ucfirst("enter-your-name")
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        required
                      />
                    </div>
                    {/* End .col-sm-6 */}

                    <div className="col-sm-6">
                      <label htmlFor="cemail" className="sr-only">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="cemail"
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            component.ucfirst("enter-your-email")
                          )
                        }
                        onInput={(e) => e.target.setCustomValidity("")}
                        placeholder="Email"
                        required
                      />
                    </div>
                    {/* End .col-sm-6 */}
                  </div>
                  {/* End .row */}

                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" className="sr-only">
                        {component.ucfirst("phone")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            component.ucfirst("enter-your-phone")
                          )
                        }
                        id="cphone"
                        onInput={(e) => e.target.setCustomValidity("")}
                        placeholder={component.ucfirst("phone")}
                        required
                      />
                    </div>
                    {/* End .col-sm-6 */}

                    <div className="col-sm-6">
                      <label htmlFor="csubject" className="sr-only">
                        {component.ucfirst("subject")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="csubject"
                        placeholder={component.ucfirst("subject")}
                      />
                    </div>
                    {/* End .col-sm-6 */}
                  </div>
                  {/* End .row */}

                  <label htmlFor="cmessage" className="sr-only">
                    {component.ucfirst("message")}
                  </label>
                  <textarea
                    className="form-control"
                    cols="30"
                    rows="4"
                    id="cmessage"
                    required
                    placeholder={component.ucfirst("message")}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        component.ucfirst("enter-your-message")
                      )
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                  ></textarea>

                  <button
                    type="submit"
                    className="btn btn-outline-primary-2 btn-minwidth-sm"
                  >
                    <span>{component.ucfirst("submit")}</span>
                    <i className="icon-long-arrow-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
