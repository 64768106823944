import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/product";
import Services from "../../Services/Services";

export default class Product extends Page {
  title = "property";
  mainLoaded = false;

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.addToVisited();
    this.getVisited();

    this.getHelpers("file")
      .scope({
        account: this.getHelpers("env").getDefaultAccount(),
        repository: this.getHelpers("env").getDefaultRepository(),
        entity: "item",
        entity_slug: this.getPage().getParam("slug", ""),
        scope: "default",
      })
      .then((data) => {
        this.setData({ "default.images": data });
      });

    Services.get("content").then(([contentService]) => {
      contentService
        .getOne("property", this.getPage().getParam("slug", ""))
        .then(({ data }) => {
          const pageTitle = this.getHelpers("env").get("title");

          this.setWindowTitle(
            `${pageTitle} - ${this.ucfirst(
              this.getHelpers("dev").getObjectValue(data, "name")
            )}`
          );

          this.setData({
            "default.item": data,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    Services.get("content").then(([contentService]) => {
      contentService
        .getAll("location")
        .then(({ data }) => {
          this.setData({
            "default.locations": data,
            "default.page": 1,
            "default.wishlist": this.getWishlist(),
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });

    window.scrollTo({
      top: 0,
      left: 0,
    });

    this.setSeoData();
  }

  async setSeoData() {
    const account = this.getHelpers("env").getDefaultAccount();
    const repository = this.getHelpers("env").getDefaultRepository();

    const translations = await this.getHelpers("translate").scope({
      account,
      repository,
      entity: "item",
      entity_slug: "seo",
      scope: "default",
    });

    const description = translations.find(
      (translation) => translation.slug === "default-description"
    ) || { translation: "" };

    const keywords = translations.find(
      (translation) => translation.slug === "default-keywords"
    ) || { translation: "" };

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description.translation);

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", keywords.translation);
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    if (this.getData("default.visited")) {
      setTimeout(() => {
        this.addScripts({
          "jquery.min": "/assets/js/jquery.min",
          "bootstrap.bundle.min": "/assets/js/bootstrap.bundle.min",
          "jquery.hoverIntent.min": "/assets/js/jquery.hoverIntent.min",
          "jquery.waypoints.min": "/assets/js/jquery.waypoints.min",
          "superfish.min": "/assets/js/superfish.min",
          "bootstrap-input-spinner": "/assets/js/bootstrap-input-spinner",
          "jquery.elevateZoom.min": "/assets/js/jquery.elevateZoom.min",
          "jquery.plugin.min": "/assets/js/jquery.plugin.min",
          "jquery.countdown.min": "/assets/js/jquery.countdown.min",
          "demo-2": "/assets/js/demos/demo-2",
          "jquery.magnific-popup.min": "/assets/js/jquery.magnific-popup.min",
          "owl.carousel.min": "/assets/js/owl.carousel.min",
          main: "/assets/js/main",
        });
      }, 1000);

      setTimeout(() => {
        window
          .$(".product-gallery-item")
          .after()
          .click(() => {
            window.scrollTo({
              top: 180,
              left: 0,
              behavior: "smooth",
            });
          });
      }, 2000);

      // this.deleteData("default.visited");
    }
  }
  getWishlist() {
    const wishlist = this.getHelpers("state").get("wishlist");
    if (wishlist) {
      var array = wishlist.split(",");
      if (array.includes(this.getPage().getParam("slug"))) {
        this.setData({
          "default.added": true,
        });
      }

      return array;
    } else {
      return [];
    }
  }

  addToWishList(slug) {
    if (this.getPage().getParam("slug") === slug) {
      this.setData({
        "default.added": true,
      });
    }
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");
    // stateHelper.remove("wishlist", {
    //   domain: Env.get("domain"),
    //   path: "/",
    // });
    var array = [];
    var wishlist = stateHelper.get("wishlist", null);
    if (wishlist) {
      array = wishlist.split(",");
      if (!array.includes(slug)) {
        array.push(slug);
      }
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    } else {
      array.push(slug);
      stateHelper.set("wishlist", array.join(","), {
        domain: Env.get("domain"),
        path: "/",
      });
    }

    this.setData({
      "default.wishlist": array,
    });
  }

  removeFromWishList(slug) {
    if (this.getPage().getParam("slug") === slug) {
      this.setData({
        "default.added": false,
      });
    }
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");

    var array = stateHelper.get("wishlist").split(",");
    var index = array.findIndex((el) => el === slug);
    array.splice(index, 1);
    stateHelper.set("wishlist", array.join(","), {
      domain: Env.get("domain"),
      path: "/",
    });

    this.setData({
      "default.wishlist": array,
    });
  }

  getVisited() {
    const visited = this.getHelpers("state").get("visited");
    const wishlist = this.getHelpers("state").get("wishlist");
    var array = [];
    if (wishlist) {
      array = wishlist.split(",");
    }

    if (visited) {
      Services.get("content").then(([content]) => {
        var promises = visited.split(",").map((slug) => {
          return content.getOne("property", slug);
        });

        Promise.all(promises).then((items) => {
          items = items.map(({ data }) => {
            var temp = data;

            if (temp) {
              temp["added"] = false;
              array.forEach((slug) => {
                if (temp.slug === slug) {
                  temp["added"] = true;
                }
              });
            }
            return temp;
          });

          this.setData({
            "default.visited": items.filter((item) => item),
            "default.visitedBoot": true,
          });
        });
      });
    }
  }

  addToVisited() {
    const slug = this.getPage().getParam("slug");
    const Env = this.getHelpers("env");
    const stateHelper = this.getHelpers("state");
    // stateHelper.remove("visited", {
    //   domain: Env.get("domain"),
    //   path: "/",
    // });

    const start = Date.now() + 86400000;
    var array = [];

    var visited = stateHelper.get("visited", null);
    if (visited) {
      array = visited.split(",");
      if (!array.includes(slug)) {
        if (array.length === 10) {
          array.shift();
        }
        array.push(slug);
      }
      stateHelper.set("visited", array.join(","), {
        domain: Env.get("domain"),
        expires: new Date(start),
        path: "/",
      });
    } else {
      array.push(slug);
      stateHelper.set("visited", array.join(","), {
        domain: Env.get("domain"),
        expires: new Date(start),
        path: "/",
      });
    }
  }

  sendForm(e) {
    e.preventDefault();
    if (isNaN(document.getElementById("cphone").value)) {
      document
        .getElementById("cphone")
        .setCustomValidity("Το τηλέφωνο πρεπει να περιέχει μόνο αριθμούς!");
    } else {
      const { name } = this.getData("default.item", {}) || {};

      const message = {};
      // message["item"] = this.getPage().getParam("slug");
      message["item"] = name;
      message["name"] = document.getElementById("cname").value;
      message["email"] = document.getElementById("cemail").value;
      message["phone"] = document.getElementById("cphone").value;
      message["subject"] = document.getElementById("csubject").value;
      message["message"] = document.getElementById("cmessage").value;
      message["link"] = window.location.href;

      Services.get("hook").then(async ([Hook]) => {
        const response = await Hook.post("contact-us", message);

        if (response.isValid()) {
          document.getElementById("cname").value = "";
          document.getElementById("cemail").value = "";
          document.getElementById("cphone").value = "";
          document.getElementById("csubject").value = "";
          document.getElementById("cmessage").value = "";
          document.getElementById("myModal").style.display = "none";
        }
      });

      // Services.get("content").then(([content]) => {
      //   content.contact(message).then(() => {
      //     document.getElementById("cname").value = "";
      //     document.getElementById("cemail").value = "";
      //     document.getElementById("cphone").value = "";
      //     document.getElementById("csubject").value = "";
      //     document.getElementById("cmessage").value = "";
      //     document.getElementById("myModal").style.display = "none";
      //   });
      // });
    }
  }

  checkSearch(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search").value.length > 1) {
      window.location.href =
        devHelper + "/results/" + document.getElementById("search").value;
    }
  }

  checkSearchMobile(e) {
    e.preventDefault();
    const devHelper = this.getHelpers("env").getPublicUrl();
    if (document.getElementById("search_mobile").value.length > 1) {
      window.location.href =
        devHelper +
        "/results/" +
        document.getElementById("search_mobile").value;
    }
  }
}
