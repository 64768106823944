import State from "./State";
import Env from "./Env";
import DateTime from "./DateTime";
import Dev from "./Dev";

class Auth {
  set(data) {
    this.data = data;
    this.assignToken();
    this.onAuth(this);
    this.loaded = true;

    return this;
  }

  get() {
    return this.data || {};
  }

  clear() {
    this.set(null).removeToken();

    return this;
  }

  isLoaded() {
    return this.loaded || false;
  }

  assignToken() {
    const token = Dev.getObjectValue(this.get(), "token");

    if (token) {
      let date = DateTime.create();
      date.setMonth(date.getMonth() + parseInt(Env.get("token_expires")));

      State.set("token", token, {
        domain: Env.get("domain"),
        expires: date,
        path: "/",
      });
    }

    return this;
  }

  getToken() {
    return State.get("token");
  }

  removeToken() {
    State.remove("token", {
      domain: Env.get("domain"),
      path: "/",
    });

    return this;
  }

  getUser(defaultValue) {
    return Dev.getObjectValue(this.get(), "user", defaultValue || null);
  }

  getUserAttribute(name) {
    const user = this.getUser() || {};
    return Dev.getObjectValue(user, name, "");
  }

  isAuthenticated() {
    return Dev.getObjectValue(this.get(), "authenticated", false);
  }

  isAuthorized() {
    return Dev.getObjectValue(this.get(), "authorized", false);
  }

  onAuth(auth) {
    //
  }

  isSu() {
    return Dev.getObjectValue(this.get(), `fullAccess`, false);
  }

  isOwner(account) {
    return (
      Dev.getObjectValue(this.get(), `access.${account}.owner`, false) ||
      this.isSu()
    );
  }

  isAdmin(account) {
    return (
      Dev.getObjectValue(this.get(), `access.${account}.admin`, false) ||
      this.isOwner(account)
    );
  }

  isAssistant(account) {
    return (
      Dev.getObjectValue(this.get(), `access.${account}.assistant`, false) ||
      this.isSu()
    );
  }

  isOwnerOrAssistant(account) {
    return this.isOwner(account) || this.isAssistant(account);
  }

  isAdminOrAssistant(account) {
    return this.isAdmin(account) || this.isAssistant(account);
  }

  hasAction(paths, account) {
    const app = Env.get("name");
    let access = this.isOwnerOrAssistant(account);

    if (!access) {
      paths = typeof paths === "string" ? [paths] : paths;

      paths.forEach((path) => {
        access =
          access ||
          Dev.getObjectValue(
            this.get(),
            `access.${account}.actions.${app}.${path}`,
            false
          ) === true;
      });
    }

    return access;
  }
}

export { Auth };
export default new Auth();
