import ShareApi from "../../Api/Share";
import Env from "./Env";

class File {
  scope(options) {
    const platformVersion = Env.get("platform_version");

    if (platformVersion === 1) {
      return this.scope1(options);
    } else {
      return this.scope2(options);
    }
  }

  scope1(options) {
    return new Promise((success, reject) => {
      options = options || {};

      const { account, app, entity, entity_slug, scope } = options;

      ShareApi.file
        .scope(account, app, entity, entity_slug, scope)
        .then((r) => {
          if (r.isValid()) {
            const data = r.getData();
            success(data);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  scope2(options) {
    return new Promise((success, reject) => {
      options = options || {};

      const { account, repository, entity, entity_slug, scope } = options;

      ShareApi.content.file
        .scope(account, repository, entity, entity_slug, scope)
        .then((r) => {
          if (r.isValid()) {
            const data = r.getData();
            success(data);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export { File };
export default new File();
