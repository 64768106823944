import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const location_items = component.getData("default.location_items", []);
  const location_name = component.getData("default.location_name", null);

  const devHelper = component.getHelpers("dev");
  const account = component.getHelpers("env").getDefaultAccount();
  const repository = component.getHelpers("env").getDefaultRepository();

  const addToWishList = (event, index, slug) => {
    event.preventDefault();
    location_items[index].added = true;
    component.addToWishList(slug);
  };

  const removeFromWishList = (event, index, slug) => {
    event.preventDefault();
    location_items[index].added = false;
    component.removeFromWishList(slug);
  };

  return (
    <React.Fragment>
      <div
        className="page-header text-center"
        style={{ backgroundColor: "unset" }}
      >
        <div className="container">
          <h1 className="page-title">
            {location_name &&
              location_name.name &&
              component.ucfirst(location_name.name)}
          </h1>
        </div>
        {/* End .container */}
      </div>
      {/* End .page-header */}

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="products mb-3">
                <div
                  className={`row ${
                    location_items && location_items.length === 1
                      ? "justify-content-center"
                      : ""
                  }`}
                >
                  {location_items &&
                    location_items.map((item, index) => {
                      return (
                        <div
                          key={`item-${devHelper.getObjectValue(item, "slug")}`}
                          className="col-lg-6 col-xs-12"
                        >
                          <div className="product product-7 text-center">
                            <figure className="product-media">
                              <a
                                href={`/property/${devHelper.getObjectValue(
                                  item,
                                  "slug"
                                )}`}
                                style={{
                                  height: "250px",
                                  backgroundImage: `url(${component
                                    .getHelpers("url")
                                    .createAccountImageUrl(
                                      account,
                                      repository,
                                      "item",
                                      devHelper.getObjectValue(item, "slug"),
                                      "default",
                                      "default"
                                    )})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              >
                                {" "}
                              </a>

                              <div className="product-action-vertical">
                                {item.added ? (
                                  <a
                                    href="/"
                                    title="Wishlist"
                                    onClick={(e) =>
                                      removeFromWishList(
                                        e,
                                        index,
                                        devHelper.getObjectValue(item, "slug")
                                      )
                                    }
                                    className="btn-product-icon btn-expandable"
                                  >
                                    <i className="icon-heart"></i>
                                    <span>
                                      {" "}
                                      {component.ucfirst(
                                        "remove-from-wishlist"
                                      )}
                                    </span>
                                  </a>
                                ) : (
                                  <a
                                    href="/"
                                    title="Wishlist"
                                    onClick={(e) =>
                                      addToWishList(
                                        e,
                                        index,
                                        devHelper.getObjectValue(item, "slug")
                                      )
                                    }
                                    className="btn-product-icon btn-expandable"
                                  >
                                    <i className="icon-heart-o"></i>
                                    <span>
                                      {" "}
                                      {component.ucfirst("add-to-wishlist")}
                                    </span>
                                  </a>
                                )}
                              </div>
                              {/* End .product-action-vertical */}
                            </figure>
                            {/* End .product-media */}

                            <div className="product-body">
                              <h3 className="product-title">
                                <a
                                  href={`/property/${devHelper.getObjectValue(
                                    item,
                                    "slug"
                                  )}`}
                                >
                                  {component.ucfirst(
                                    devHelper.getObjectValue(item, "name")
                                  )}
                                </a>
                              </h3>
                              {item.price > 0 && (
                                <div>
                                  <span>
                                    {devHelper.getObjectValue(item, "price")} €
                                  </span>
                                </div>
                              )}
                            </div>
                            {/* End .product-body */}
                          </div>
                          {/* End .product */}
                        </div>
                      );
                    })}
                </div>
                {/* End .row */}
              </div>
              {/* End .products */}
            </div>
            {/* End .col-lg-9 */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
      </div>
      {/* End .page-content */}
    </React.Fragment>
  );
};

export default ExportDefault;
