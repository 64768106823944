import Dev from "./Dev";

class Validate {
  required(value, fieldRule) {
    let valid = Dev.isDefined(value);

    if (valid) {
      if (Array.isArray(value)) {
        valid = value.length > 0;
      } else {
        valid = value !== null && value !== "";
      }
    }

    return valid;
  }

  range(value, fieldRule) {
    let { min, max } = fieldRule;
    const length = typeof value !== "object" ? value.length : 0;
    let valid = true;

    if (length) {
      if (min) {
        valid = valid && length >= min;
      }

      if (max) {
        valid = valid && length <= max;
      }
    }

    return valid;
  }

  regex(value, fieldRule) {
    fieldRule = fieldRule || {};
    let { regex } = fieldRule;
    return value && regex ? regex.test(value) : true;
  }

  email(value, fieldRule) {
    fieldRule = fieldRule || {};
    fieldRule.regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return this.regex(value, fieldRule);
  }

  boolean(value, fieldRule) {
    value = value ? value + "" : null;
    return value ? value === "1" || value === "0" : true;
  }

  number(value, fieldRule) {
    return value ? !isNaN(value * 1) : true;
  }

  integer(value, fieldRule) {
    return value ? Number.isInteger(value * 1) : true;
  }

  integerPos(value, fieldRule) {
    return value ? this.integer(value) && value >= 0 : true;
  }

  validate(object, fields) {
    fields = fields || {};
    let errors = null;

    for (let path in fields) {
      const rules = fields[path];
      const value = Dev.getObjectValue(object, path);
      const fieldErrors = [];

      rules.forEach((fieldRule) => {
        const { rule } = fieldRule;

        if (rule && this[rule]) {
          if (!this[rule](value, fieldRule)) {
            fieldErrors.push(fieldRule);
          }
        }
      });

      if (fieldErrors.length) {
        if (!errors) {
          errors = {};
        }

        Dev.setObjectValue(errors, path, fieldErrors);
      }
    }

    return errors;
  }
}

export { Validate };
export default new Validate();
