import React from "react";

const ExportDefault = (props) => {
  const { component } = props;
  const locations = component.getData("default.locations", []);
  const valueHelper = component.getHelpers("value");
  const facebookLink = valueHelper.getValue("facebook-link");
  const instagramLink = valueHelper.getValue("instagram-link");

  return (
    <React.Fragment>
      <div className="mobile-menu-overlay"></div>
      <div className="mobile-menu-container mobile-menu-light">
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close">
            <i className="icon-close"></i>
          </span>

          <form
            onSubmit={(e) => component.checkSearchMobile(e)}
            method="get"
            className="mobile-search"
          >
            <label htmlFor="mobile-search" className="sr-only">
              {component.ucfirst("search")}
            </label>
            <input
              type="text"
              className="form-control"
              name="mobile-search"
              id="search_mobile"
              placeholder={component.ucfirst("search-property")}
            />
            <button
              className="btn btn-primary"
              id="btn-mobile-search"
              type="submit"
            >
              <i className="icon-search"></i>
            </button>
          </form>
          <nav className="mobile-nav">
            <ul className="mobile-menu">
              <li className="active nav_liks">
                <a href="/">{component.ucfirst("home-title")}</a>
              </li>

              <li>
                <a
                  href="/"
                  onClick={(e) => e.preventDefault()}
                  className="sf-with-ul nav_liks"
                >
                  {component.ucfirst("locations")}
                </a>

                <ul>
                  {locations.map((location) => {
                    const { slug, name } = location;
                    return (
                      <li key={`location-${slug}`}>
                        <a
                          href={`/location/${slug}`}
                          className="location-name text-capitalize"
                        >
                          {name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li>
                <a className="nav_liks" href="/contact-us">
                  {component.ucfirst("contact-us-title")}
                </a>
              </li>
            </ul>
          </nav>
          {/* End .mobile-nav */}
          {(facebookLink || instagramLink) && (
            <div className="social-icons">
              {facebookLink && (
                <a
                  href={facebookLink}
                  className="social-icon"
                  target="_blank"
                  rel="noreferrer"
                  title="Facebook"
                >
                  <i className="icon-facebook-f"></i>
                </a>
              )}
              {instagramLink && (
                <a
                  href="https://www.instagram.com/casadipatsi/"
                  className="social-icon"
                  target="_blank"
                  rel="noreferrer"
                  title="Instagram"
                >
                  <i className="icon-instagram"></i>
                </a>
              )}
            </div>
          )}
          {/* End .social-icons */}
        </div>
        {/* End .mobile-menu-wrapper */}
      </div>
    </React.Fragment>
  );
};

export default ExportDefault;
