import { Component } from "../Component";

export default class Page extends Component {
  persist = false;
  title = "";

  constructor(props) {
    super(props);

    this.setId("page");
    this.getHelpers("const").set("page", this);
    this.getComponentsState().clear();
  }

  getTitle() {
    return this.getData(
      "default.title",
      this.title || this.getHelpers("env").get("name")
    );
  }

  setTitle(title) {
    title = this.ucfirst(title);
    return this.setData({ "default.title": title });
  }

  setWindowTitle(title) {
    document.title = title;
  }

  getParams() {
    return this.props.match.params || {};
  }

  getParam(param, defaultValue) {
    return this.getHelpers("dev").getObjectValue(
      this.getParams(),
      param,
      defaultValue
    );
  }

  getQuery(param, defaultValue) {
    defaultValue = this.getHelpers("dev").define(defaultValue);
    const search = this.props.location.search;

    if (search) {
      let params = {};

      search
        .split("?")[1]
        .split("&")
        .forEach((param) => {
          param = param.split("=");
          params[param[0]] = param[1] || "";
        });

      if (param) {
        return params[param] || defaultValue;
      } else {
        return params;
      }
    }

    return defaultValue;
  }

  getPathname(defaultValue) {
    return (
      this.props.location.pathname ||
      this.getHelpers("dev").define(defaultValue)
    );
  }

  goBack() {
    this.props.history.goBack();
    return this;
  }

  scrollTop(element, top) {
    top = top || 0;
    element.scrollTop = top;

    return this;
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
    });

    return this;
  }

  scrollTopPage(top) {
    const Env = this.getHelpers("env");

    if (Env.isDesktop()) {
      this.scrollTop(null, top);
    } else if (Env.isMobile()) {
      this.scrollTop(document.querySelector("html"), top);
    } else {
      this.scrollTop(document.scrollingElement, top);
    }

    return this;
  }

  reload() {
    if (window.location) {
      window.location.reload();
    }

    return this;
  }

  redirect(url, decode) {
    const Url = this.getHelpers("url");
    url = decode ? Url.decode(url) : url;

    if (Url.isExternalUrl(url)) {
      Url.redirect(url);
    } else {
      this.props.history.push(url);
    }

    return this;
  }

  redirectTo(url) {
    const Url = this.getHelpers("url");
    let redirect = Url.encode(
      Url.isExternalUrl(url)
        ? window.location.href
        : `${this.props.location.pathname}${this.props.location.search}`
    );
    this.redirect(`${url}?redirect=${redirect}`);
    return this;
  }

  redirectToRedirect(defaultUrl) {
    defaultUrl = defaultUrl || "/";
    const redirectUrl = this.getQuery("redirect");

    if (redirectUrl) {
      this.redirect(redirectUrl, true);
    } else {
      this.redirect(defaultUrl);
    }
    return this;
  }

  redirectToSafe() {
    const Env = this.getHelpers("env");
    this.redirectTo(Env.get("safe_url"));
    return this;
  }

  redirectToLogin() {
    const Env = this.getHelpers("env");
    this.redirectTo(Env.get("login_url"));
    return this;
  }

  redirectToRegister() {
    const Env = this.getHelpers("env");
    this.redirectTo(Env.get("register_url"));
    return this;
  }

  logout() {
    this.getHelpers("auth").clear();

    if (this.isFullAuth()) {
      this.redirectToLogin();
    } else {
      this.notifyAuth();
    }

    return this;
  }

  login() {
    this.notifyAuth();
    return this;
  }

  onAuth(data) {
    //const { auth, boot = false } = data;
    const { boot = false } = data;

    if (boot && this.isFullAuth()) {
      this.refresh();
    }
  }

  /*
  onAuth(data) {
    const { boot = false } = data;

    if (boot && this.isFullAuth()) {
      const Url = Helpers.Url;
      const Env = Helpers.Env;
      const Auth = Helpers.Auth;
      const loginUrl = Env.get("login_url");
      const safeUrl = Env.get("safe_url");
      const accountUrl = Env.get("account_url");
      const authenticated = Auth.isAuthenticated();
      const authorized = Auth.isAuthorized();
      const currentPath = window.location.pathname;
      let url = null;

      if (!authenticated) {
        url = loginUrl;
      } else if (!authorized) {
        url = currentPath === "/" ? accountUrl : safeUrl;
      }

      if (url) {
        if (Url.isExternalUrl(url)) {
          Url.redirect(
            `${url}${
              !authenticated
                ? `?redirect=${Url.encode(window.location.href)}`
                : ""
            }`
          );
        } else {
          this.redirectTo(url);
        }
      } else {
        this.refresh();
      }
    }
  }
  */

  addScript(name, path, async, cb) {
    let script = document.createElement("script");
    script.setAttribute("src", `${path}.js`);
    script.id = name;
    script.async = async || false;
    script.onload = () => {
      if (cb) {
        cb(name);
      }
    };
    document.body.appendChild(script);

    return this;
  }

  removeScript(name) {
    let script = document.getElementById(name);

    if (script) {
      script.remove();
    }

    return this;
  }

  addScripts(scripts, remove, async, cb) {
    for (var name in scripts) {
      if (remove) {
        this.removeScript(name);
      }

      if (!this.scriptIsLoaded(name)) {
        this.addScript(name, scripts[name], async, cb);
      }
    }

    return this;
  }

  scriptIsLoaded(name) {
    return document.getElementById(name);
  }

  addStyle(name, path, media, cb) {
    let stylesheet = document.createElement("link");
    stylesheet.setAttribute("href", `${path}.css`);
    stylesheet.rel = "stylesheet";
    stylesheet.type = "text/css";
    stylesheet.id = name;
    stylesheet.media = media || "all";
    stylesheet.onload = () => {
      if (cb) {
        cb(name);
      }
    };

    document.head.appendChild(stylesheet);

    return this;
  }

  removeStyle(name) {
    let stylesheet = document.getElementById(name);

    if (stylesheet) {
      stylesheet.remove();
    }

    return this;
  }

  addStyles(stylesheets, remove, media, cb) {
    for (var name in stylesheets) {
      if (remove) {
        this.removeStyle(name);
      }

      if (!this.styleIsLoaded(name)) {
        this.addStyle(name, stylesheets[name], media, cb);
      }
    }

    return this;
  }

  styleIsLoaded(name) {
    return document.getElementById(name);
  }
}
